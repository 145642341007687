import useSWRInfinite from "swr/infinite";
import { useDataLayer } from "data-layer";
import { useMemo } from "react";
export function useApplications(options) {
  const dataLayer = useDataLayer();
  const {
    data,
    error,
    size,
    setSize
  } = useSWRInfinite(pageIndex => options === null ? null : [pageIndex, options, "/applications"], async _ref => {
    let [pageIndex] = _ref;
    // The first argument to useSRWInfinite will ensure that this function
    // never gets called if options is `null`. If it's still called, we fail
    // early and clearly.
    if (options === null) {
      throw new Error("Bug");
    }
    switch (options.type) {
      case "applications-search":
        {
          const {
            results,
            pagination
          } = await dataLayer.searchApplications({
            page: pageIndex,
            ...options
          });

          // unzip data and meta
          const applications = results.map(result => result.data);
          const applicationMeta = results.map(result => result.meta);
          return {
            applications,
            applicationMeta,
            pagination
          };
        }
      case "applications-paginated":
        {
          const {
            applications,
            pagination
          } = await dataLayer.getApplicationsPaginated({
            page: pageIndex,
            ...options
          });
          return {
            applications,
            pagination,
            applicationMeta: []
          };
        }
      case "applications-collection":
        {
          var _options$filter;
          if (((_options$filter = options.filter) === null || _options$filter === void 0 ? void 0 : _options$filter.type) === "expanded-refs") {
            var _options$filter$refs, _options$filter2;
            const applications = await dataLayer.getApprovedApplicationsByExpandedRefs((_options$filter$refs = (_options$filter2 = options.filter) === null || _options$filter2 === void 0 ? void 0 : _options$filter2.refs) !== null && _options$filter$refs !== void 0 ? _options$filter$refs : []);
            const v2Applications = applications.filter(a => {
              var _a$tags;
              return (_a$tags = a.tags) === null || _a$tags === void 0 ? void 0 : _a$tags.includes("allo-v2");
            });
            return {
              applications: v2Applications,
              pagination: {
                currentPage: 1,
                totalPages: 1,
                totalItems: v2Applications.length
              },
              applicationMeta: []
            };
          } else {
            throw new Error("Unsupported filter type");
          }
        }
    }
  }, {
    revalidateIfStale: false,
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
    revalidateFirstPage: false
  });
  const applications = useMemo(() => {
    var _data$flatMap;
    return (_data$flatMap = data === null || data === void 0 ? void 0 : data.flatMap(page => page.applications)) !== null && _data$flatMap !== void 0 ? _data$flatMap : [];
  }, [data]);
  const applicationMeta = useMemo(() => {
    var _data$flatMap2;
    return (_data$flatMap2 = data === null || data === void 0 ? void 0 : data.flatMap(page => page.applicationMeta)) !== null && _data$flatMap2 !== void 0 ? _data$flatMap2 : [];
  }, [data]);
  const totalApplicationsCount = data !== undefined && data.length > 0 ? data[data.length - 1].pagination.totalItems : 0;
  return {
    applications,
    applicationMeta,
    isLoading: !data && !error,
    isLoadingMore: size > 0 && typeof (data === null || data === void 0 ? void 0 : data[size - 1]) === "undefined" && !error,
    loadedPageCount: size,
    totalApplicationsCount,
    loadNextPage: () => setSize(size + 1),
    error,
    hasMorePages: totalApplicationsCount > applications.length
  };
}
const PROJECTS_SORTING_SEED = Math.random();
export function createApplicationFetchOptions(_ref2) {
  let {
    searchQuery = "",
    category,
    collection,
    filters
  } = _ref2;
  let applicationsFetchOptions = {
    type: "applications-paginated",
    filter: filterListToApplicationFilter(filters),
    order: {
      type: "random",
      seed: PROJECTS_SORTING_SEED
    }
  };
  if (searchQuery.length > 0) {
    applicationsFetchOptions = {
      type: "applications-search",
      queryString: searchQuery
    };
  } else if (category !== undefined) {
    applicationsFetchOptions = {
      type: "applications-search",
      queryString: `${category.searchQuery} --strategy=semantic`
    };
  } else if (collection !== undefined) {
    applicationsFetchOptions = {
      type: "applications-collection",
      filter: {
        type: "expanded-refs",
        refs: collection.applications
      },
      order: {
        type: "random",
        seed: PROJECTS_SORTING_SEED
      }
    };
  }
  return applicationsFetchOptions;
}
function filterListToApplicationFilter(filters) {
  const filteredByChainIds = filters.reduce((acc, filter) => {
    if (filter.type === "chain") {
      return [...acc, filter.chainId];
    }
    return acc;
  }, []);
  if (filteredByChainIds.length > 0) {
    return {
      type: "chains",
      chainIds: filteredByChainIds
    };
  }
  return undefined;
}