import { getTokenByChainIdAndAddress } from "common";
export const calculateTotalContributions = contributions => {
  let totalDonations = 0;
  let totalUniqueContributions = 0;
  const projects = [];
  contributions === null || contributions === void 0 || contributions.forEach(contribution => {
    const token = getTokenByChainIdAndAddress(contribution.chainId, contribution.tokenAddress);
    if (token) {
      totalDonations += contribution.amountInUsd;
      totalUniqueContributions += 1;
      const project = contribution.projectId;
      if (!projects.includes(project)) {
        projects.push(project);
      }
    }
  });
  return {
    totalDonations,
    totalUniqueContributions,
    totalProjectsFunded: projects.length
  };
};