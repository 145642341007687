/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { create } from "zustand";
import { devtools } from "zustand/middleware";
import { persist } from "zustand/middleware";
export const useAttestationStore = create()(persist(devtools((set, get) => ({
  checkedOutTransactions: {},
  addCheckedOutTransaction: (tx, address) => {
    if (!address) {
      return;
    }
    set(oldState => ({
      checkedOutTransactions: {
        ...oldState.checkedOutTransactions,
        [address]: [...(oldState.checkedOutTransactions[address] || []), tx]
      }
    }));
  },
  getCheckedOutTransactions: address => {
    if (!address) {
      return [];
    }
    return get().checkedOutTransactions[address] || [];
  },
  cleanCheckedOutTransactions: () => {
    set({
      checkedOutTransactions: {}
    });
  }
})), {
  name: "attestation-store",
  version: 1.01
}));