/* tslint:disable */
/* eslint-disable */
/**
 * FastAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime.js";
import type { SearchType } from "./SearchType.js";
import {
  SearchTypeFromJSON,
  SearchTypeFromJSONTyped,
  SearchTypeToJSON,
} from "./SearchType.js";

/**
 *
 * @export
 * @interface SearchResultMeta
 */
export interface SearchResultMeta {
  /**
   *
   * @type {SearchType}
   * @memberof SearchResultMeta
   */
  searchType: SearchType;
  /**
   *
   * @type {number}
   * @memberof SearchResultMeta
   */
  searchScore: number;
}

/**
 * Check if a given object implements the SearchResultMeta interface.
 */
export function instanceOfSearchResultMeta(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && "searchType" in value;
  isInstance = isInstance && "searchScore" in value;

  return isInstance;
}

export function SearchResultMetaFromJSON(json: any): SearchResultMeta {
  return SearchResultMetaFromJSONTyped(json, false);
}

export function SearchResultMetaFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): SearchResultMeta {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    searchType: SearchTypeFromJSON(json["searchType"]),
    searchScore: json["searchScore"],
  };
}

export function SearchResultMetaToJSON(value?: SearchResultMeta | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    searchType: SearchTypeToJSON(value.searchType),
    searchScore: value.searchScore,
  };
}
