export const truncate = function () {
  let str = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : "";
  let max = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 13;
  let sep = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : "...";
  const len = str.length;
  if (len > max) {
    const seplen = sep.length;
    if (seplen > max) {
      return str.substr(len - max);
    }
    const n = -0.5 * (max - len - seplen);
    const center = len / 2;
    const front = str.substr(0, center - n);
    const back = str.substr(len - center + n);
    return front + sep + back;
  }
  return str;
};