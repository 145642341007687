import { useReadContract } from "wagmi";
import { easConfig, schemaByNetwork, VERSION_ABI, legacyABI, proxyABI } from "./config";
import { EAS__factory } from "@ethereum-attestation-service/eas-contracts";

/**
 * Hook to manage EAS configuration based on chainId.
 */
export const useEASConfig = chainId => {
  var _easConfig$chainId, _easConfig$chainId2;
  let easAddress = (_easConfig$chainId = easConfig[chainId]) === null || _easConfig$chainId === void 0 ? void 0 : _easConfig$chainId.eas;
  const proxy = (_easConfig$chainId2 = easConfig[chainId]) === null || _easConfig$chainId2 === void 0 ? void 0 : _easConfig$chainId2.proxy;
  if (proxy) {
    easAddress = proxy;
  }
  const {
    data: version
  } = useReadContract({
    chainId,
    address: easAddress,
    abi: VERSION_ABI,
    functionName: "VERSION"
  });
  const abi = proxy ? proxyABI : version !== undefined ? legacyABI : EAS__factory.abi;
  const schema = schemaByNetwork[chainId];
  return {
    easAddress,
    abi,
    schema
  };
};