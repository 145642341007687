import { useState } from "react";
import useSWR from "swr";
import { gql, GraphQLClient } from "graphql-request";
const osoApiKey = process.env.REACT_APP_OSO_API_KEY;
const osoUrl = "https://www.opensource.observer/api/v1/graphql";
const graphQLClient = new GraphQLClient(osoUrl, {
  headers: {
    authorization: `Bearer ${osoApiKey}`
  }
});
let fetchedProject = "";
export function useOSO(projectGithub) {
  const emptyReturn = {
    oso_codeMetricsByProjectV1: [{
      contributorCount: 0,
      firstCommitDate: 0,
      activeDeveloperCount6Months: 0
    }]
  };
  const [stats, setStats] = useState(null);
  const getStatsFor = async projectRegistryGithub => {
    fetchedProject = projectRegistryGithub;
    if (osoApiKey === "") throw new Error("OpenSourceObserver API key not set.");
    const queryVars = {
      where: {
        displayName: {
          _ilike: `${projectRegistryGithub}`
        }
      }
    };
    const queryStats = gql`
      query myQuery($where: Oso_CodeMetricsByProjectV1BoolExp) {
        oso_codeMetricsByProjectV1(where: $where) {
          contributorCount
          firstCommitDate
          activeDeveloperCount6Months
        }
      }
    `;
    try {
      var _items$oso_codeMetric;
      const items = await graphQLClient.request(queryStats, queryVars);
      if (!((_items$oso_codeMetric = items.oso_codeMetricsByProjectV1) !== null && _items$oso_codeMetric !== void 0 && _items$oso_codeMetric.length)) {
        throw new Error("no stats returned");
      }
      const parsedItems = {
        oso_codeMetricsByProjectV1: items.oso_codeMetricsByProjectV1
      };
      setStats(parsedItems);
    } catch (e) {
      console.error(`No stats found for project: ${projectGithub}`);
      console.error(e);
      setStats(emptyReturn);
    }
  };
  const {
    isLoading
  } = useSWR(osoUrl, {
    fetcher: async () => projectGithub && getStatsFor(projectGithub),
    revalidateOnMount: true
  });
  if (fetchedProject !== projectGithub)
    // check if currently loaded stats are for viewed project
    projectGithub && getStatsFor(projectGithub); // fetch if not
  return {
    /**
     * Fetch OSO for stats on a project
     * @param projectRegistryGithub projectGithub
     */
    getStatsFor,
    /**
     * Stats for a project (loaded from OSO)
     */
    stats,
    isStatsLoading: isLoading
  };
}