import useSWR from "swr";
export function useRoundApprovedApplications(params, dataLayer) {
  const shouldFetch = Object.values(params).every(Boolean);
  return useSWR(shouldFetch ? ["allApprovedApplications", params] : null, async () => {
    if (params.chainId === undefined || params.roundId === undefined) {
      return null;
    }
    return await dataLayer.getApplicationsForExplorer({
      roundId: params.roundId,
      chainId: params.chainId
    });
  });
}