import useSWR from "swr";
export function useApplication(params, dataLayer) {
  const shouldFetch = Object.values(params).every(Boolean);
  return useSWR(shouldFetch ? ["applications", params] : null, async () => {
    var _await$dataLayer$getA;
    const validatedParams = {
      chainId: params.chainId,
      applicationId: params.applicationId,
      roundId: params.roundId
    };
    return (_await$dataLayer$getA = await dataLayer.getApprovedApplication(validatedParams)) !== null && _await$dataLayer$getA !== void 0 ? _await$dataLayer$getA : undefined;
  });
}

// These functions map the application data to fit the shape of the view
// Changing the view would require significant changes to the markup + cart storage
export function mapApplicationToProject(application) {
  var _application$metadata;
  return {
    grantApplicationId: application.id,
    applicationIndex: Number(application.id),
    projectRegistryId: application.projectId,
    recipient: application.metadata.application.recipient,
    projectMetadata: application.project.metadata,
    status: application.status,
    grantApplicationFormAnswers: (_application$metadata = application.metadata.application.answers) !== null && _application$metadata !== void 0 ? _application$metadata : [],
    anchorAddress: application.anchorAddress
  };
}
export function mapApplicationToRound(application) {
  return {
    roundEndTime: new Date(application.round.donationsEndTime),
    roundStartTime: new Date(application.round.donationsStartTime),
    applicationsStartTime: new Date(application.round.applicationsStartTime),
    applicationsEndTime: new Date(application.round.applicationsEndTime),
    roundMetadata: application.round.roundMetadata,
    token: application.round.matchTokenAddress,
    payoutStrategy: {
      id: "id",
      strategyName: application.round.strategyName
    },
    // These might not be used anywhere in the app
    votingStrategy: "",
    ownedBy: ""
  };
}