/* tslint:disable */
/* eslint-disable */
/**
 * FastAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime.js";
import type { SearchResult } from "./SearchResult.js";
import {
  SearchResultFromJSON,
  SearchResultFromJSONTyped,
  SearchResultToJSON,
} from "./SearchResult.js";

/**
 *
 * @export
 * @interface SearchResponse
 */
export interface SearchResponse {
  /**
   *
   * @type {Array<SearchResult>}
   * @memberof SearchResponse
   */
  results: Array<SearchResult>;
}

/**
 * Check if a given object implements the SearchResponse interface.
 */
export function instanceOfSearchResponse(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && "results" in value;

  return isInstance;
}

export function SearchResponseFromJSON(json: any): SearchResponse {
  return SearchResponseFromJSONTyped(json, false);
}

export function SearchResponseFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): SearchResponse {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    results: (json["results"] as Array<any>).map(SearchResultFromJSON),
  };
}

export function SearchResponseToJSON(value?: SearchResponse | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    results: (value.results as Array<any>).map(SearchResultToJSON),
  };
}
