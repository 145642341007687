import { BaseError, ContractFunctionRevertedError, InsufficientFundsError, UserRejectedRequestError } from "viem";

/**
 * Custom Error class for handling transaction-related errors.
 */
export class TransactionError extends Error {
  constructor(reason, data) {
    super(reason);
    this.reason = reason;
    this.data = data;
  }
}

/**
 * Function to handle and rethrow transaction errors with user-friendly messages.
 */
export function handleTransactionError(error) {
  if (error instanceof BaseError) {
    if (error instanceof ContractFunctionRevertedError) {
      var _error$data$errorName, _error$data;
      const errorName = (_error$data$errorName = (_error$data = error.data) === null || _error$data === void 0 ? void 0 : _error$data.errorName) !== null && _error$data$errorName !== void 0 ? _error$data$errorName : "Unknown Error";
      throw new TransactionError(errorName, {
        message: errorName
      });
    }
    if (error instanceof InsufficientFundsError) {
      throw new TransactionError("Insufficient Funds", {
        message: "Insufficient funds to complete the transaction."
      });
    }
    if (error instanceof UserRejectedRequestError) {
      throw new TransactionError("User Rejected Request", {
        message: "You have rejected the request."
      });
    }
  }

  // Handle unknown errors
  throw new TransactionError("Transaction Failed", {
    message: "An unknown error occurred."
  });
}