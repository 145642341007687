import useSWR from "swr";
export function useProject(params, dataLayer) {
  return useSWR(["projects", params], async () => {
    var _await$dataLayer$getP;
    const validatedParams = {
      projectId: params.projectId,
      alloVersion: "allo-v2"
    };
    return (_await$dataLayer$getP = await dataLayer.getProjectById(validatedParams)) !== null && _await$dataLayer$getP !== void 0 ? _await$dataLayer$getP : undefined;
  });
}
export function useProjectApplications(params, dataLayer) {
  // Fetch legacy project ID first
  const {
    data: legacyProjectId
  } = useSWR(["v1ProjectId", params], async () => {
    var _await$dataLayer$getL;
    const validatedParams = {
      projectId: params.projectId
    };
    return (_await$dataLayer$getL = await dataLayer.getLegacyProjectId(validatedParams)) !== null && _await$dataLayer$getL !== void 0 ? _await$dataLayer$getL : undefined;
  });

  // Fetch project applications based on both v2 and legacy project IDs
  const projectIds = legacyProjectId ? [params.projectId, legacyProjectId] : [params.projectId];
  const {
    data: applications,
    error
  } = useSWR(["applications", {
    projectIds
  }], async () => {
    var _await$dataLayer$getA;
    const validatedParams = {
      projectIds: projectIds
    };
    return (_await$dataLayer$getA = await dataLayer.getApprovedApplicationsByProjectIds(validatedParams)) !== null && _await$dataLayer$getA !== void 0 ? _await$dataLayer$getA : undefined;
  });
  return {
    applications,
    isLoading: !applications && !error,
    error
  };
}