import useSWRImmutable from "swr/immutable";
import { zeroAddress } from "viem";
import { getConfig } from "common/src/config";

/* TODO: Rename some of the types to hungarian-style notation once we have shared types between indexer and frontends */

const config = getConfig();
function getMatchingEstimates(params) {
  if (config.explorer.disableEstimates) {
    throw new Error("matching estimate temporarily disabled");
  }
  const replacer = (_key, value) => typeof value === "bigint" ? value.toString() : value;

  /* The indexer wants just the application id number, not the whole application */
  const fixedApplicationId = params.potentialVotes.map(vote => ({
    ...vote,
    applicationId: vote.applicationId.includes("-") ? vote.applicationId.split("-")[1] : vote.applicationId
  }));
  return fetch(`${process.env.REACT_APP_INDEXER_V2_API_URL}/api/v1/chains/${params.chainId}/rounds/${params.roundId}/estimate`, {
    headers: {
      Accept: "application/json",
      "content-type": "application/json"
    },
    body: JSON.stringify({
      potentialVotes: fixedApplicationId
    }, replacer),
    method: "POST"
  }).then(r => r.json());
}

/**
 * Fetches matching estimates for the given rounds, given potential votes, as an array
 * For a single round, pass in an array with a single element
 */
export function useMatchingEstimates(params) {
  const shouldFetch = params.every(param => param.roundId !== zeroAddress);
  return useSWRImmutable(shouldFetch ? params : null, params => Promise.all(params.map(params => getMatchingEstimates(params))));
}
export function matchingEstimatesToText(matchingEstimates) {
  return matchingEstimates === null || matchingEstimates === void 0 ? void 0 : matchingEstimates.flat().map(est => {
    var _est$differenceInUSD;
    return (_est$differenceInUSD = est.differenceInUSD) !== null && _est$differenceInUSD !== void 0 ? _est$differenceInUSD : 0;
  }).filter(diff => diff > 0).reduce((acc, b) => acc + b, 0);
}