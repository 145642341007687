import { SORT_OPTIONS } from "../SortDropdown";
export function getSortLabel(_ref) {
  var _SORT_OPTIONS$find;
  let {
    orderBy = "NATURAL"
  } = _ref;
  return (_SORT_OPTIONS$find = SORT_OPTIONS.find(item => item.orderBy === orderBy)) !== null && _SORT_OPTIONS$find !== void 0 ? _SORT_OPTIONS$find : {
    label: "",
    orderBy: "NATURAL"
  };
}