import { Collection } from "../data.types";

export const COLLECTIONS_HARDCODED: Collection[] = [
  {
    id: "first-time-grantees",
    author: "Gitcoin",
    name: "First Time Grantees",
    images: [
      // TODO: ideally these should be external, absolute URLs.
      "/assets/collections/collection_01.jpg",
      "/assets/collections/collection_02.jpg",
      "/assets/collections/collection_03.jpg",
      "/assets/collections/collection_04.jpg",
    ],
    description:
      "This collection showcases all grantees in GG19 that have not participated in a past round on Grants Stack! Give these first-time grantees some love (and maybe some donations, too!).",
    applicationRefs: [
      "10:0x36f548e082b09b0cec5b3f5a7b78953c75de5e74:2",
      "10:0x36f548e082b09b0cec5b3f5a7b78953c75de5e74:8",
      "10:0x36f548e082b09b0cec5b3f5a7b78953c75de5e74:7",
      "10:0x36f548e082b09b0cec5b3f5a7b78953c75de5e74:6",
      "10:0x36f548e082b09b0cec5b3f5a7b78953c75de5e74:4",
      "10:0x36f548e082b09b0cec5b3f5a7b78953c75de5e74:5",
      "10:0x36f548e082b09b0cec5b3f5a7b78953c75de5e74:3",
      "10:0x36f548e082b09b0cec5b3f5a7b78953c75de5e74:0",
      "10:0x4727e3265706c59dbc31e7c518960f4f843bb4da:7",
      "10:0x4727e3265706c59dbc31e7c518960f4f843bb4da:13",
      "10:0x4727e3265706c59dbc31e7c518960f4f843bb4da:5",
      "10:0x4727e3265706c59dbc31e7c518960f4f843bb4da:11",
      "10:0x4727e3265706c59dbc31e7c518960f4f843bb4da:2",
      "10:0x4727e3265706c59dbc31e7c518960f4f843bb4da:14",
      "10:0x4727e3265706c59dbc31e7c518960f4f843bb4da:10",
      "10:0x4727e3265706c59dbc31e7c518960f4f843bb4da:9",
      "10:0x4727e3265706c59dbc31e7c518960f4f843bb4da:12",
      "10:0x5eb890e41c8d2cff75ea942085e406bb90016561:98",
      "10:0x5eb890e41c8d2cff75ea942085e406bb90016561:7",
      "10:0x5eb890e41c8d2cff75ea942085e406bb90016561:22",
      "10:0x5eb890e41c8d2cff75ea942085e406bb90016561:60",
      "10:0x5eb890e41c8d2cff75ea942085e406bb90016561:45",
      "10:0x5eb890e41c8d2cff75ea942085e406bb90016561:71",
      "10:0x5eb890e41c8d2cff75ea942085e406bb90016561:96",
      "10:0x5eb890e41c8d2cff75ea942085e406bb90016561:101",
      "10:0x5eb890e41c8d2cff75ea942085e406bb90016561:90",
      "10:0x5eb890e41c8d2cff75ea942085e406bb90016561:47",
      "10:0x5eb890e41c8d2cff75ea942085e406bb90016561:112",
      "10:0x5eb890e41c8d2cff75ea942085e406bb90016561:28",
      "10:0x5eb890e41c8d2cff75ea942085e406bb90016561:17",
      "10:0x5eb890e41c8d2cff75ea942085e406bb90016561:33",
      "10:0x5eb890e41c8d2cff75ea942085e406bb90016561:18",
      "10:0x5eb890e41c8d2cff75ea942085e406bb90016561:25",
      "10:0x5eb890e41c8d2cff75ea942085e406bb90016561:52",
      "10:0x5eb890e41c8d2cff75ea942085e406bb90016561:50",
      "10:0x5eb890e41c8d2cff75ea942085e406bb90016561:68",
      "10:0x5eb890e41c8d2cff75ea942085e406bb90016561:79",
      "10:0x5eb890e41c8d2cff75ea942085e406bb90016561:89",
      "10:0x5eb890e41c8d2cff75ea942085e406bb90016561:1",
      "10:0x5eb890e41c8d2cff75ea942085e406bb90016561:59",
      "10:0x5eb890e41c8d2cff75ea942085e406bb90016561:4",
      "10:0x5eb890e41c8d2cff75ea942085e406bb90016561:70",
      "10:0x5eb890e41c8d2cff75ea942085e406bb90016561:40",
      "10:0x5eb890e41c8d2cff75ea942085e406bb90016561:57",
      "10:0x5eb890e41c8d2cff75ea942085e406bb90016561:49",
      "10:0x5eb890e41c8d2cff75ea942085e406bb90016561:19",
      "10:0x5eb890e41c8d2cff75ea942085e406bb90016561:114",
      "10:0x5eb890e41c8d2cff75ea942085e406bb90016561:54",
      "10:0x5eb890e41c8d2cff75ea942085e406bb90016561:84",
      "10:0x5eb890e41c8d2cff75ea942085e406bb90016561:62",
      "10:0x5eb890e41c8d2cff75ea942085e406bb90016561:23",
      "10:0x5eb890e41c8d2cff75ea942085e406bb90016561:64",
      "10:0x5eb890e41c8d2cff75ea942085e406bb90016561:116",
      "10:0x5eb890e41c8d2cff75ea942085e406bb90016561:76",
      "10:0x6726fe9c89fb04eaef388c11cf55be6aa0a62fb9:26",
      "10:0x6726fe9c89fb04eaef388c11cf55be6aa0a62fb9:19",
      "10:0x6726fe9c89fb04eaef388c11cf55be6aa0a62fb9:10",
      "10:0x6726fe9c89fb04eaef388c11cf55be6aa0a62fb9:15",
      "10:0xb5c0939a9bb0c404b028d402493b86d9998af55e:2",
      "10:0xb5c0939a9bb0c404b028d402493b86d9998af55e:0",
      "10:0xb5c0939a9bb0c404b028d402493b86d9998af55e:3",
      "10:0xb5c0939a9bb0c404b028d402493b86d9998af55e:4",
      "10:0xb5c0939a9bb0c404b028d402493b86d9998af55e:1",
      "10:0xc34745b3852df32d5958be88df2bee0a83474001:10",
      "10:0xc34745b3852df32d5958be88df2bee0a83474001:39",
      "10:0xc34745b3852df32d5958be88df2bee0a83474001:7",
      "10:0xc34745b3852df32d5958be88df2bee0a83474001:19",
      "10:0xc34745b3852df32d5958be88df2bee0a83474001:18",
      "10:0xc34745b3852df32d5958be88df2bee0a83474001:15",
      "10:0xc34745b3852df32d5958be88df2bee0a83474001:16",
      "10:0xc9a01d3d2505d9d2418dd2da64d06cf53fd403a0:1",
      "10:0xc9a01d3d2505d9d2418dd2da64d06cf53fd403a0:7",
      "10:0xc9a01d3d2505d9d2418dd2da64d06cf53fd403a0:12",
      "10:0xc9a01d3d2505d9d2418dd2da64d06cf53fd403a0:10",
      "10:0xc9a01d3d2505d9d2418dd2da64d06cf53fd403a0:19",
      "10:0xc9a01d3d2505d9d2418dd2da64d06cf53fd403a0:17",
      "10:0xc9a01d3d2505d9d2418dd2da64d06cf53fd403a0:20",
      "10:0xc9a01d3d2505d9d2418dd2da64d06cf53fd403a0:11",
      "10:0xc9a01d3d2505d9d2418dd2da64d06cf53fd403a0:16",
      "10:0xc9a01d3d2505d9d2418dd2da64d06cf53fd403a0:5",
      "10:0xc9a01d3d2505d9d2418dd2da64d06cf53fd403a0:18",
      "10:0xc9a01d3d2505d9d2418dd2da64d06cf53fd403a0:3",
      "10:0xc9a01d3d2505d9d2418dd2da64d06cf53fd403a0:4",
      "10:0xc9a01d3d2505d9d2418dd2da64d06cf53fd403a0:13",
      "10:0xc9a01d3d2505d9d2418dd2da64d06cf53fd403a0:6",
      "10:0xc9a01d3d2505d9d2418dd2da64d06cf53fd403a0:15",
      "10:0xc9a01d3d2505d9d2418dd2da64d06cf53fd403a0:21",
      "10:0xc9a01d3d2505d9d2418dd2da64d06cf53fd403a0:14",
      "10:0xc9a01d3d2505d9d2418dd2da64d06cf53fd403a0:2",
      "10:0xd309defd59c0b8792b14197eaa40043d9625b22b:0",
      "10:0xd309defd59c0b8792b14197eaa40043d9625b22b:1",
      "10:0xd309defd59c0b8792b14197eaa40043d9625b22b:10",
      "10:0xd309defd59c0b8792b14197eaa40043d9625b22b:8",
      "10:0xd309defd59c0b8792b14197eaa40043d9625b22b:5",
      "10:0xd309defd59c0b8792b14197eaa40043d9625b22b:7",
      "10:0xd309defd59c0b8792b14197eaa40043d9625b22b:4",
      "424:0x98720dd1925d34a2453ebc1f91c9d48e7e89ec29:215",
      "424:0x98720dd1925d34a2453ebc1f91c9d48e7e89ec29:223",
      "424:0x98720dd1925d34a2453ebc1f91c9d48e7e89ec29:203",
      "424:0x98720dd1925d34a2453ebc1f91c9d48e7e89ec29:199",
      "424:0x98720dd1925d34a2453ebc1f91c9d48e7e89ec29:235",
      "424:0x98720dd1925d34a2453ebc1f91c9d48e7e89ec29:230",
      "424:0x98720dd1925d34a2453ebc1f91c9d48e7e89ec29:221",
      "424:0x98720dd1925d34a2453ebc1f91c9d48e7e89ec29:229",
      "424:0x98720dd1925d34a2453ebc1f91c9d48e7e89ec29:245",
      "424:0x98720dd1925d34a2453ebc1f91c9d48e7e89ec29:216",
      "424:0x98720dd1925d34a2453ebc1f91c9d48e7e89ec29:237",
      "424:0x98720dd1925d34a2453ebc1f91c9d48e7e89ec29:239",
      "424:0x98720dd1925d34a2453ebc1f91c9d48e7e89ec29:226",
      "424:0x98720dd1925d34a2453ebc1f91c9d48e7e89ec29:234",
      "424:0x98720dd1925d34a2453ebc1f91c9d48e7e89ec29:222",
      "424:0x98720dd1925d34a2453ebc1f91c9d48e7e89ec29:246",
      "424:0x98720dd1925d34a2453ebc1f91c9d48e7e89ec29:249",
      "424:0x98720dd1925d34a2453ebc1f91c9d48e7e89ec29:257",
      "424:0x98720dd1925d34a2453ebc1f91c9d48e7e89ec29:244",
      "424:0x98720dd1925d34a2453ebc1f91c9d48e7e89ec29:260",
      "424:0x98720dd1925d34a2453ebc1f91c9d48e7e89ec29:267",
      "424:0x98720dd1925d34a2453ebc1f91c9d48e7e89ec29:272",
      "424:0x98720dd1925d34a2453ebc1f91c9d48e7e89ec29:273",
      "424:0x98720dd1925d34a2453ebc1f91c9d48e7e89ec29:253",
      "424:0x98720dd1925d34a2453ebc1f91c9d48e7e89ec29:259",
      "424:0x98720dd1925d34a2453ebc1f91c9d48e7e89ec29:233",
      "424:0x98720dd1925d34a2453ebc1f91c9d48e7e89ec29:268",
      "424:0x98720dd1925d34a2453ebc1f91c9d48e7e89ec29:271",
      "424:0x98720dd1925d34a2453ebc1f91c9d48e7e89ec29:232",
      "424:0x98720dd1925d34a2453ebc1f91c9d48e7e89ec29:258",
      "424:0x98720dd1925d34a2453ebc1f91c9d48e7e89ec29:282",
      "424:0x98720dd1925d34a2453ebc1f91c9d48e7e89ec29:241",
      "424:0x98720dd1925d34a2453ebc1f91c9d48e7e89ec29:242",
      "424:0x98720dd1925d34a2453ebc1f91c9d48e7e89ec29:255",
      "424:0x98720dd1925d34a2453ebc1f91c9d48e7e89ec29:251",
      "424:0x98720dd1925d34a2453ebc1f91c9d48e7e89ec29:139",
      "424:0x98720dd1925d34a2453ebc1f91c9d48e7e89ec29:225",
      "424:0x98720dd1925d34a2453ebc1f91c9d48e7e89ec29:269",
      "424:0x98720dd1925d34a2453ebc1f91c9d48e7e89ec29:275",
      "424:0x98720dd1925d34a2453ebc1f91c9d48e7e89ec29:278",
      "424:0x98720dd1925d34a2453ebc1f91c9d48e7e89ec29:264",
      "424:0x98720dd1925d34a2453ebc1f91c9d48e7e89ec29:172",
      "424:0x98720dd1925d34a2453ebc1f91c9d48e7e89ec29:276",
      "424:0x98720dd1925d34a2453ebc1f91c9d48e7e89ec29:281",
      "424:0x98720dd1925d34a2453ebc1f91c9d48e7e89ec29:94",
      "424:0x98720dd1925d34a2453ebc1f91c9d48e7e89ec29:240",
      "424:0x98720dd1925d34a2453ebc1f91c9d48e7e89ec29:250",
      "424:0x98720dd1925d34a2453ebc1f91c9d48e7e89ec29:104",
      "424:0x98720dd1925d34a2453ebc1f91c9d48e7e89ec29:218",
      "424:0x98720dd1925d34a2453ebc1f91c9d48e7e89ec29:34",
      "424:0x98720dd1925d34a2453ebc1f91c9d48e7e89ec29:85",
      "424:0x98720dd1925d34a2453ebc1f91c9d48e7e89ec29:88",
      "424:0x98720dd1925d34a2453ebc1f91c9d48e7e89ec29:212",
      "424:0x98720dd1925d34a2453ebc1f91c9d48e7e89ec29:175",
      "424:0x98720dd1925d34a2453ebc1f91c9d48e7e89ec29:13",
      "424:0x98720dd1925d34a2453ebc1f91c9d48e7e89ec29:6",
      "424:0x98720dd1925d34a2453ebc1f91c9d48e7e89ec29:8",
      "424:0x98720dd1925d34a2453ebc1f91c9d48e7e89ec29:21",
      "424:0x98720dd1925d34a2453ebc1f91c9d48e7e89ec29:30",
      "424:0x98720dd1925d34a2453ebc1f91c9d48e7e89ec29:7",
      "424:0x98720dd1925d34a2453ebc1f91c9d48e7e89ec29:18",
      "424:0x98720dd1925d34a2453ebc1f91c9d48e7e89ec29:20",
      "424:0x98720dd1925d34a2453ebc1f91c9d48e7e89ec29:22",
      "424:0x98720dd1925d34a2453ebc1f91c9d48e7e89ec29:41",
      "424:0x98720dd1925d34a2453ebc1f91c9d48e7e89ec29:5",
      "424:0x98720dd1925d34a2453ebc1f91c9d48e7e89ec29:37",
      "424:0x98720dd1925d34a2453ebc1f91c9d48e7e89ec29:38",
      "424:0x98720dd1925d34a2453ebc1f91c9d48e7e89ec29:51",
      "424:0x98720dd1925d34a2453ebc1f91c9d48e7e89ec29:27",
      "424:0x98720dd1925d34a2453ebc1f91c9d48e7e89ec29:31",
      "424:0x98720dd1925d34a2453ebc1f91c9d48e7e89ec29:32",
      "424:0x98720dd1925d34a2453ebc1f91c9d48e7e89ec29:42",
      "424:0x98720dd1925d34a2453ebc1f91c9d48e7e89ec29:28",
      "424:0x98720dd1925d34a2453ebc1f91c9d48e7e89ec29:44",
      "424:0x98720dd1925d34a2453ebc1f91c9d48e7e89ec29:45",
      "424:0x98720dd1925d34a2453ebc1f91c9d48e7e89ec29:52",
      "424:0x98720dd1925d34a2453ebc1f91c9d48e7e89ec29:57",
      "424:0x98720dd1925d34a2453ebc1f91c9d48e7e89ec29:74",
      "424:0x98720dd1925d34a2453ebc1f91c9d48e7e89ec29:77",
      "424:0x98720dd1925d34a2453ebc1f91c9d48e7e89ec29:81",
      "424:0x98720dd1925d34a2453ebc1f91c9d48e7e89ec29:40",
      "424:0x98720dd1925d34a2453ebc1f91c9d48e7e89ec29:56",
      "424:0x98720dd1925d34a2453ebc1f91c9d48e7e89ec29:60",
      "424:0x98720dd1925d34a2453ebc1f91c9d48e7e89ec29:91",
      "424:0x98720dd1925d34a2453ebc1f91c9d48e7e89ec29:92",
      "424:0x98720dd1925d34a2453ebc1f91c9d48e7e89ec29:78",
      "424:0x98720dd1925d34a2453ebc1f91c9d48e7e89ec29:82",
      "424:0x98720dd1925d34a2453ebc1f91c9d48e7e89ec29:108",
      "424:0x98720dd1925d34a2453ebc1f91c9d48e7e89ec29:62",
      "424:0x98720dd1925d34a2453ebc1f91c9d48e7e89ec29:58",
      "424:0x98720dd1925d34a2453ebc1f91c9d48e7e89ec29:64",
      "424:0x98720dd1925d34a2453ebc1f91c9d48e7e89ec29:83",
      "424:0x98720dd1925d34a2453ebc1f91c9d48e7e89ec29:97",
      "424:0x98720dd1925d34a2453ebc1f91c9d48e7e89ec29:100",
      "424:0x98720dd1925d34a2453ebc1f91c9d48e7e89ec29:90",
      "424:0x98720dd1925d34a2453ebc1f91c9d48e7e89ec29:101",
      "424:0x98720dd1925d34a2453ebc1f91c9d48e7e89ec29:68",
      "424:0x98720dd1925d34a2453ebc1f91c9d48e7e89ec29:76",
      "424:0x98720dd1925d34a2453ebc1f91c9d48e7e89ec29:80",
      "424:0x98720dd1925d34a2453ebc1f91c9d48e7e89ec29:87",
      "424:0x98720dd1925d34a2453ebc1f91c9d48e7e89ec29:103",
      "424:0x98720dd1925d34a2453ebc1f91c9d48e7e89ec29:105",
      "424:0x98720dd1925d34a2453ebc1f91c9d48e7e89ec29:95",
      "424:0x98720dd1925d34a2453ebc1f91c9d48e7e89ec29:99",
      "424:0x98720dd1925d34a2453ebc1f91c9d48e7e89ec29:119",
      "424:0x98720dd1925d34a2453ebc1f91c9d48e7e89ec29:107",
      "424:0x98720dd1925d34a2453ebc1f91c9d48e7e89ec29:112",
      "424:0x98720dd1925d34a2453ebc1f91c9d48e7e89ec29:132",
      "424:0x98720dd1925d34a2453ebc1f91c9d48e7e89ec29:133",
      "424:0x98720dd1925d34a2453ebc1f91c9d48e7e89ec29:149",
      "424:0x98720dd1925d34a2453ebc1f91c9d48e7e89ec29:155",
      "424:0x98720dd1925d34a2453ebc1f91c9d48e7e89ec29:121",
      "424:0x98720dd1925d34a2453ebc1f91c9d48e7e89ec29:144",
      "424:0x98720dd1925d34a2453ebc1f91c9d48e7e89ec29:125",
      "424:0x98720dd1925d34a2453ebc1f91c9d48e7e89ec29:122",
      "424:0x98720dd1925d34a2453ebc1f91c9d48e7e89ec29:136",
      "424:0x98720dd1925d34a2453ebc1f91c9d48e7e89ec29:140",
      "424:0x98720dd1925d34a2453ebc1f91c9d48e7e89ec29:152",
      "424:0x98720dd1925d34a2453ebc1f91c9d48e7e89ec29:154",
      "424:0x98720dd1925d34a2453ebc1f91c9d48e7e89ec29:134",
      "424:0x98720dd1925d34a2453ebc1f91c9d48e7e89ec29:159",
      "424:0x98720dd1925d34a2453ebc1f91c9d48e7e89ec29:163",
      "424:0x98720dd1925d34a2453ebc1f91c9d48e7e89ec29:135",
      "424:0x98720dd1925d34a2453ebc1f91c9d48e7e89ec29:157",
      "424:0x98720dd1925d34a2453ebc1f91c9d48e7e89ec29:177",
      "424:0x98720dd1925d34a2453ebc1f91c9d48e7e89ec29:147",
      "424:0x98720dd1925d34a2453ebc1f91c9d48e7e89ec29:168",
      "424:0x98720dd1925d34a2453ebc1f91c9d48e7e89ec29:170",
      "424:0x98720dd1925d34a2453ebc1f91c9d48e7e89ec29:191",
      "424:0x98720dd1925d34a2453ebc1f91c9d48e7e89ec29:143",
      "424:0x98720dd1925d34a2453ebc1f91c9d48e7e89ec29:148",
      "424:0x98720dd1925d34a2453ebc1f91c9d48e7e89ec29:153",
      "424:0x98720dd1925d34a2453ebc1f91c9d48e7e89ec29:167",
      "424:0x98720dd1925d34a2453ebc1f91c9d48e7e89ec29:178",
      "424:0x98720dd1925d34a2453ebc1f91c9d48e7e89ec29:146",
      "424:0x98720dd1925d34a2453ebc1f91c9d48e7e89ec29:174",
      "424:0x98720dd1925d34a2453ebc1f91c9d48e7e89ec29:200",
      "424:0x98720dd1925d34a2453ebc1f91c9d48e7e89ec29:202",
      "424:0x98720dd1925d34a2453ebc1f91c9d48e7e89ec29:169",
      "424:0x98720dd1925d34a2453ebc1f91c9d48e7e89ec29:183",
      "424:0x98720dd1925d34a2453ebc1f91c9d48e7e89ec29:186",
      "424:0x98720dd1925d34a2453ebc1f91c9d48e7e89ec29:198",
      "424:0x98720dd1925d34a2453ebc1f91c9d48e7e89ec29:187",
      "424:0x98720dd1925d34a2453ebc1f91c9d48e7e89ec29:190",
      "424:0x98720dd1925d34a2453ebc1f91c9d48e7e89ec29:210",
      "424:0x98720dd1925d34a2453ebc1f91c9d48e7e89ec29:213",
      "424:0x98720dd1925d34a2453ebc1f91c9d48e7e89ec29:180",
      "424:0x98720dd1925d34a2453ebc1f91c9d48e7e89ec29:196",
      "424:0x98720dd1925d34a2453ebc1f91c9d48e7e89ec29:208",
      "424:0xd4cc0dd193c7dc1d665ae244ce12d7fab337a008:17",
      "424:0xd4cc0dd193c7dc1d665ae244ce12d7fab337a008:63",
      "424:0xd4cc0dd193c7dc1d665ae244ce12d7fab337a008:62",
      "424:0xd4cc0dd193c7dc1d665ae244ce12d7fab337a008:26",
      "424:0xd4cc0dd193c7dc1d665ae244ce12d7fab337a008:39",
      "424:0xd4cc0dd193c7dc1d665ae244ce12d7fab337a008:27",
      "424:0xd4cc0dd193c7dc1d665ae244ce12d7fab337a008:25",
      "424:0xd4cc0dd193c7dc1d665ae244ce12d7fab337a008:37",
      "424:0xd4cc0dd193c7dc1d665ae244ce12d7fab337a008:31",
      "424:0xd4cc0dd193c7dc1d665ae244ce12d7fab337a008:19",
      "424:0xd4cc0dd193c7dc1d665ae244ce12d7fab337a008:10",
      "424:0xd4cc0dd193c7dc1d665ae244ce12d7fab337a008:18",
      "424:0xd4cc0dd193c7dc1d665ae244ce12d7fab337a008:4",
      "424:0xd4cc0dd193c7dc1d665ae244ce12d7fab337a008:29",
      "424:0xd4cc0dd193c7dc1d665ae244ce12d7fab337a008:11",
      "424:0xd4cc0dd193c7dc1d665ae244ce12d7fab337a008:14",
      "424:0xd4cc0dd193c7dc1d665ae244ce12d7fab337a008:13",
      "424:0xd4cc0dd193c7dc1d665ae244ce12d7fab337a008:91",
      "424:0xd4cc0dd193c7dc1d665ae244ce12d7fab337a008:85",
      "424:0xd4cc0dd193c7dc1d665ae244ce12d7fab337a008:48",
      "424:0xd4cc0dd193c7dc1d665ae244ce12d7fab337a008:38",
      "424:0xd4cc0dd193c7dc1d665ae244ce12d7fab337a008:137",
      "424:0xd4cc0dd193c7dc1d665ae244ce12d7fab337a008:138",
      "424:0xd4cc0dd193c7dc1d665ae244ce12d7fab337a008:130",
      "424:0xd4cc0dd193c7dc1d665ae244ce12d7fab337a008:132",
      "424:0xd4cc0dd193c7dc1d665ae244ce12d7fab337a008:129",
      "424:0xd4cc0dd193c7dc1d665ae244ce12d7fab337a008:128",
      "424:0xd4cc0dd193c7dc1d665ae244ce12d7fab337a008:115",
      "424:0xd4cc0dd193c7dc1d665ae244ce12d7fab337a008:133",
      "424:0xd4cc0dd193c7dc1d665ae244ce12d7fab337a008:127",
      "424:0xd4cc0dd193c7dc1d665ae244ce12d7fab337a008:119",
      "424:0xd4cc0dd193c7dc1d665ae244ce12d7fab337a008:101",
      "424:0xd4cc0dd193c7dc1d665ae244ce12d7fab337a008:102",
      "424:0xd4cc0dd193c7dc1d665ae244ce12d7fab337a008:94",
      "424:0xd4cc0dd193c7dc1d665ae244ce12d7fab337a008:83",
      "424:0xd4cc0dd193c7dc1d665ae244ce12d7fab337a008:100",
      "424:0xd4cc0dd193c7dc1d665ae244ce12d7fab337a008:98",
      "424:0xd4cc0dd193c7dc1d665ae244ce12d7fab337a008:96",
      "424:0xd4cc0dd193c7dc1d665ae244ce12d7fab337a008:99",
      "424:0xd4cc0dd193c7dc1d665ae244ce12d7fab337a008:64",
      "424:0xd4cc0dd193c7dc1d665ae244ce12d7fab337a008:61",
      "424:0xd4cc0dd193c7dc1d665ae244ce12d7fab337a008:84",
      "424:0xd4cc0dd193c7dc1d665ae244ce12d7fab337a008:68",
      "424:0xd4cc0dd193c7dc1d665ae244ce12d7fab337a008:46",
      "424:0xd4cc0dd193c7dc1d665ae244ce12d7fab337a008:80",
      "424:0xd4cc0dd193c7dc1d665ae244ce12d7fab337a008:56",
      "424:0xd4cc0dd193c7dc1d665ae244ce12d7fab337a008:49",
      "424:0xd4cc0dd193c7dc1d665ae244ce12d7fab337a008:67",
      "424:0xe60a569ec8aac2045d9fda306dc2a16cc1e52a90:18",
      "424:0xe60a569ec8aac2045d9fda306dc2a16cc1e52a90:25",
      "424:0xe60a569ec8aac2045d9fda306dc2a16cc1e52a90:20",
      "424:0xe60a569ec8aac2045d9fda306dc2a16cc1e52a90:5",
      "424:0xe60a569ec8aac2045d9fda306dc2a16cc1e52a90:2",
      "424:0xe60a569ec8aac2045d9fda306dc2a16cc1e52a90:6",
      "424:0xe60a569ec8aac2045d9fda306dc2a16cc1e52a90:16",
      "424:0xe60a569ec8aac2045d9fda306dc2a16cc1e52a90:7",
      "424:0xe60a569ec8aac2045d9fda306dc2a16cc1e52a90:11",
      "424:0xe60a569ec8aac2045d9fda306dc2a16cc1e52a90:23",
      "424:0xe60a569ec8aac2045d9fda306dc2a16cc1e52a90:28",
      "42161:0x3ac78e1ae5086904d53b41c747188216789f59a7:25",
      "42161:0x3ac78e1ae5086904d53b41c747188216789f59a7:22",
      "42161:0x3ac78e1ae5086904d53b41c747188216789f59a7:27",
      "42161:0x3ac78e1ae5086904d53b41c747188216789f59a7:15",
      "42161:0x3ac78e1ae5086904d53b41c747188216789f59a7:8",
      "42161:0x3ac78e1ae5086904d53b41c747188216789f59a7:19",
      "42161:0x3ac78e1ae5086904d53b41c747188216789f59a7:9",
      "42161:0x3ac78e1ae5086904d53b41c747188216789f59a7:10",
      "42161:0x3ac78e1ae5086904d53b41c747188216789f59a7:16",
    ],
  },
  {
    id: "grants-stack-veterans",
    author: "Gitcoin",
    name: "Grants Stack Veterans",
    images: [
      "/assets/collections/collection_05.jpg",
      "/assets/collections/collection_06.jpg",
      "/assets/collections/collection_07.jpg",
      "/assets/collections/collection_08.jpg",
    ],
    description:
      "This collection showcases all grantees in GG19 that have participated in a past GG18 and/or Beta Round! Give these Grants Stack Veterans some love (and maybe some donations, too!).",
    applicationRefs: [
      "10:0x36f548e082b09b0cec5b3f5a7b78953c75de5e74:1",
      "10:0x4727e3265706c59dbc31e7c518960f4f843bb4da:16",
      "10:0x4727e3265706c59dbc31e7c518960f4f843bb4da:3",
      "10:0x5eb890e41c8d2cff75ea942085e406bb90016561:12",
      "10:0x5eb890e41c8d2cff75ea942085e406bb90016561:97",
      "10:0x5eb890e41c8d2cff75ea942085e406bb90016561:44",
      "10:0x5eb890e41c8d2cff75ea942085e406bb90016561:93",
      "10:0x5eb890e41c8d2cff75ea942085e406bb90016561:100",
      "10:0x5eb890e41c8d2cff75ea942085e406bb90016561:39",
      "10:0x5eb890e41c8d2cff75ea942085e406bb90016561:10",
      "10:0x5eb890e41c8d2cff75ea942085e406bb90016561:115",
      "10:0x5eb890e41c8d2cff75ea942085e406bb90016561:37",
      "10:0x5eb890e41c8d2cff75ea942085e406bb90016561:106",
      "10:0x5eb890e41c8d2cff75ea942085e406bb90016561:55",
      "10:0x5eb890e41c8d2cff75ea942085e406bb90016561:56",
      "10:0x5eb890e41c8d2cff75ea942085e406bb90016561:46",
      "10:0x5eb890e41c8d2cff75ea942085e406bb90016561:36",
      "10:0x5eb890e41c8d2cff75ea942085e406bb90016561:85",
      "10:0x5eb890e41c8d2cff75ea942085e406bb90016561:95",
      "10:0x5eb890e41c8d2cff75ea942085e406bb90016561:29",
      "10:0x5eb890e41c8d2cff75ea942085e406bb90016561:11",
      "10:0x5eb890e41c8d2cff75ea942085e406bb90016561:43",
      "10:0x5eb890e41c8d2cff75ea942085e406bb90016561:34",
      "10:0x5eb890e41c8d2cff75ea942085e406bb90016561:110",
      "10:0x5eb890e41c8d2cff75ea942085e406bb90016561:104",
      "10:0x5eb890e41c8d2cff75ea942085e406bb90016561:2",
      "10:0x5eb890e41c8d2cff75ea942085e406bb90016561:0",
      "10:0x5eb890e41c8d2cff75ea942085e406bb90016561:109",
      "10:0x5eb890e41c8d2cff75ea942085e406bb90016561:30",
      "10:0x5eb890e41c8d2cff75ea942085e406bb90016561:67",
      "10:0x5eb890e41c8d2cff75ea942085e406bb90016561:32",
      "10:0x5eb890e41c8d2cff75ea942085e406bb90016561:26",
      "10:0x5eb890e41c8d2cff75ea942085e406bb90016561:3",
      "10:0x5eb890e41c8d2cff75ea942085e406bb90016561:27",
      "10:0x5eb890e41c8d2cff75ea942085e406bb90016561:16",
      "10:0x5eb890e41c8d2cff75ea942085e406bb90016561:20",
      "10:0x5eb890e41c8d2cff75ea942085e406bb90016561:38",
      "10:0x5eb890e41c8d2cff75ea942085e406bb90016561:24",
      "10:0x5eb890e41c8d2cff75ea942085e406bb90016561:48",
      "10:0x5eb890e41c8d2cff75ea942085e406bb90016561:31",
      "10:0x5eb890e41c8d2cff75ea942085e406bb90016561:35",
      "10:0x5eb890e41c8d2cff75ea942085e406bb90016561:66",
      "10:0x5eb890e41c8d2cff75ea942085e406bb90016561:69",
      "10:0x5eb890e41c8d2cff75ea942085e406bb90016561:80",
      "10:0x5eb890e41c8d2cff75ea942085e406bb90016561:113",
      "10:0x5eb890e41c8d2cff75ea942085e406bb90016561:108",
      "10:0x5eb890e41c8d2cff75ea942085e406bb90016561:13",
      "10:0x5eb890e41c8d2cff75ea942085e406bb90016561:6",
      "10:0x5eb890e41c8d2cff75ea942085e406bb90016561:63",
      "10:0x5eb890e41c8d2cff75ea942085e406bb90016561:107",
      "10:0x5eb890e41c8d2cff75ea942085e406bb90016561:88",
      "10:0x5eb890e41c8d2cff75ea942085e406bb90016561:111",
      "10:0x5eb890e41c8d2cff75ea942085e406bb90016561:87",
      "10:0x5eb890e41c8d2cff75ea942085e406bb90016561:86",
      "10:0x5eb890e41c8d2cff75ea942085e406bb90016561:72",
      "10:0x6726fe9c89fb04eaef388c11cf55be6aa0a62fb9:5",
      "10:0x6726fe9c89fb04eaef388c11cf55be6aa0a62fb9:32",
      "10:0x6726fe9c89fb04eaef388c11cf55be6aa0a62fb9:24",
      "10:0x6726fe9c89fb04eaef388c11cf55be6aa0a62fb9:11",
      "10:0x6726fe9c89fb04eaef388c11cf55be6aa0a62fb9:20",
      "10:0x6726fe9c89fb04eaef388c11cf55be6aa0a62fb9:25",
      "10:0x6726fe9c89fb04eaef388c11cf55be6aa0a62fb9:0",
      "10:0x6726fe9c89fb04eaef388c11cf55be6aa0a62fb9:8",
      "10:0xc34745b3852df32d5958be88df2bee0a83474001:37",
      "10:0xc34745b3852df32d5958be88df2bee0a83474001:6",
      "10:0xc34745b3852df32d5958be88df2bee0a83474001:0",
      "10:0xc34745b3852df32d5958be88df2bee0a83474001:14",
      "10:0xc34745b3852df32d5958be88df2bee0a83474001:5",
      "10:0xc34745b3852df32d5958be88df2bee0a83474001:11",
      "10:0xc34745b3852df32d5958be88df2bee0a83474001:3",
      "10:0xc34745b3852df32d5958be88df2bee0a83474001:27",
      "10:0xc34745b3852df32d5958be88df2bee0a83474001:23",
      "10:0xc34745b3852df32d5958be88df2bee0a83474001:29",
      "10:0xc34745b3852df32d5958be88df2bee0a83474001:8",
      "10:0xc34745b3852df32d5958be88df2bee0a83474001:1",
      "10:0xc34745b3852df32d5958be88df2bee0a83474001:25",
      "10:0xc34745b3852df32d5958be88df2bee0a83474001:35",
      "10:0xc34745b3852df32d5958be88df2bee0a83474001:9",
      "10:0xd309defd59c0b8792b14197eaa40043d9625b22b:2",
      "10:0xd309defd59c0b8792b14197eaa40043d9625b22b:3",
      "424:0x98720dd1925d34a2453ebc1f91c9d48e7e89ec29:228",
      "424:0x98720dd1925d34a2453ebc1f91c9d48e7e89ec29:231",
      "424:0x98720dd1925d34a2453ebc1f91c9d48e7e89ec29:252",
      "424:0x98720dd1925d34a2453ebc1f91c9d48e7e89ec29:266",
      "424:0x98720dd1925d34a2453ebc1f91c9d48e7e89ec29:256",
      "424:0x98720dd1925d34a2453ebc1f91c9d48e7e89ec29:265",
      "424:0x98720dd1925d34a2453ebc1f91c9d48e7e89ec29:75",
      "424:0x98720dd1925d34a2453ebc1f91c9d48e7e89ec29:247",
      "424:0x98720dd1925d34a2453ebc1f91c9d48e7e89ec29:254",
      "424:0x98720dd1925d34a2453ebc1f91c9d48e7e89ec29:261",
      "424:0x98720dd1925d34a2453ebc1f91c9d48e7e89ec29:207",
      "424:0x98720dd1925d34a2453ebc1f91c9d48e7e89ec29:243",
      "424:0x98720dd1925d34a2453ebc1f91c9d48e7e89ec29:248",
      "424:0x98720dd1925d34a2453ebc1f91c9d48e7e89ec29:270",
      "424:0x98720dd1925d34a2453ebc1f91c9d48e7e89ec29:274",
      "424:0x98720dd1925d34a2453ebc1f91c9d48e7e89ec29:217",
      "424:0x98720dd1925d34a2453ebc1f91c9d48e7e89ec29:262",
      "424:0x98720dd1925d34a2453ebc1f91c9d48e7e89ec29:277",
      "424:0x98720dd1925d34a2453ebc1f91c9d48e7e89ec29:24",
      "424:0x98720dd1925d34a2453ebc1f91c9d48e7e89ec29:63",
      "424:0x98720dd1925d34a2453ebc1f91c9d48e7e89ec29:126",
      "424:0x98720dd1925d34a2453ebc1f91c9d48e7e89ec29:98",
      "424:0x98720dd1925d34a2453ebc1f91c9d48e7e89ec29:279",
      "424:0x98720dd1925d34a2453ebc1f91c9d48e7e89ec29:280",
      "424:0x98720dd1925d34a2453ebc1f91c9d48e7e89ec29:176",
      "424:0x98720dd1925d34a2453ebc1f91c9d48e7e89ec29:161",
      "424:0x98720dd1925d34a2453ebc1f91c9d48e7e89ec29:211",
      "424:0x98720dd1925d34a2453ebc1f91c9d48e7e89ec29:166",
      "424:0x98720dd1925d34a2453ebc1f91c9d48e7e89ec29:61",
      "424:0x98720dd1925d34a2453ebc1f91c9d48e7e89ec29:96",
      "424:0x98720dd1925d34a2453ebc1f91c9d48e7e89ec29:2",
      "424:0x98720dd1925d34a2453ebc1f91c9d48e7e89ec29:1",
      "424:0x98720dd1925d34a2453ebc1f91c9d48e7e89ec29:3",
      "424:0x98720dd1925d34a2453ebc1f91c9d48e7e89ec29:12",
      "424:0x98720dd1925d34a2453ebc1f91c9d48e7e89ec29:9",
      "424:0x98720dd1925d34a2453ebc1f91c9d48e7e89ec29:11",
      "424:0x98720dd1925d34a2453ebc1f91c9d48e7e89ec29:0",
      "424:0x98720dd1925d34a2453ebc1f91c9d48e7e89ec29:35",
      "424:0x98720dd1925d34a2453ebc1f91c9d48e7e89ec29:10",
      "424:0x98720dd1925d34a2453ebc1f91c9d48e7e89ec29:19",
      "424:0x98720dd1925d34a2453ebc1f91c9d48e7e89ec29:49",
      "424:0x98720dd1925d34a2453ebc1f91c9d48e7e89ec29:33",
      "424:0x98720dd1925d34a2453ebc1f91c9d48e7e89ec29:48",
      "424:0x98720dd1925d34a2453ebc1f91c9d48e7e89ec29:17",
      "424:0x98720dd1925d34a2453ebc1f91c9d48e7e89ec29:14",
      "424:0x98720dd1925d34a2453ebc1f91c9d48e7e89ec29:15",
      "424:0x98720dd1925d34a2453ebc1f91c9d48e7e89ec29:39",
      "424:0x98720dd1925d34a2453ebc1f91c9d48e7e89ec29:46",
      "424:0x98720dd1925d34a2453ebc1f91c9d48e7e89ec29:26",
      "424:0x98720dd1925d34a2453ebc1f91c9d48e7e89ec29:25",
      "424:0x98720dd1925d34a2453ebc1f91c9d48e7e89ec29:43",
      "424:0x98720dd1925d34a2453ebc1f91c9d48e7e89ec29:50",
      "424:0x98720dd1925d34a2453ebc1f91c9d48e7e89ec29:72",
      "424:0x98720dd1925d34a2453ebc1f91c9d48e7e89ec29:36",
      "424:0x98720dd1925d34a2453ebc1f91c9d48e7e89ec29:53",
      "424:0x98720dd1925d34a2453ebc1f91c9d48e7e89ec29:55",
      "424:0x98720dd1925d34a2453ebc1f91c9d48e7e89ec29:59",
      "424:0x98720dd1925d34a2453ebc1f91c9d48e7e89ec29:73",
      "424:0x98720dd1925d34a2453ebc1f91c9d48e7e89ec29:84",
      "424:0x98720dd1925d34a2453ebc1f91c9d48e7e89ec29:54",
      "424:0x98720dd1925d34a2453ebc1f91c9d48e7e89ec29:89",
      "424:0x98720dd1925d34a2453ebc1f91c9d48e7e89ec29:93",
      "424:0x98720dd1925d34a2453ebc1f91c9d48e7e89ec29:113",
      "424:0x98720dd1925d34a2453ebc1f91c9d48e7e89ec29:70",
      "424:0x98720dd1925d34a2453ebc1f91c9d48e7e89ec29:71",
      "424:0x98720dd1925d34a2453ebc1f91c9d48e7e89ec29:116",
      "424:0x98720dd1925d34a2453ebc1f91c9d48e7e89ec29:110",
      "424:0x98720dd1925d34a2453ebc1f91c9d48e7e89ec29:114",
      "424:0x98720dd1925d34a2453ebc1f91c9d48e7e89ec29:109",
      "424:0x98720dd1925d34a2453ebc1f91c9d48e7e89ec29:106",
      "424:0x98720dd1925d34a2453ebc1f91c9d48e7e89ec29:115",
      "424:0x98720dd1925d34a2453ebc1f91c9d48e7e89ec29:129",
      "424:0x98720dd1925d34a2453ebc1f91c9d48e7e89ec29:127",
      "424:0x98720dd1925d34a2453ebc1f91c9d48e7e89ec29:124",
      "424:0x98720dd1925d34a2453ebc1f91c9d48e7e89ec29:118",
      "424:0x98720dd1925d34a2453ebc1f91c9d48e7e89ec29:120",
      "424:0x98720dd1925d34a2453ebc1f91c9d48e7e89ec29:130",
      "424:0x98720dd1925d34a2453ebc1f91c9d48e7e89ec29:145",
      "424:0x98720dd1925d34a2453ebc1f91c9d48e7e89ec29:138",
      "424:0x98720dd1925d34a2453ebc1f91c9d48e7e89ec29:128",
      "424:0x98720dd1925d34a2453ebc1f91c9d48e7e89ec29:162",
      "424:0x98720dd1925d34a2453ebc1f91c9d48e7e89ec29:137",
      "424:0x98720dd1925d34a2453ebc1f91c9d48e7e89ec29:142",
      "424:0x98720dd1925d34a2453ebc1f91c9d48e7e89ec29:150",
      "424:0x98720dd1925d34a2453ebc1f91c9d48e7e89ec29:193",
      "424:0x98720dd1925d34a2453ebc1f91c9d48e7e89ec29:197",
      "424:0x98720dd1925d34a2453ebc1f91c9d48e7e89ec29:160",
      "424:0x98720dd1925d34a2453ebc1f91c9d48e7e89ec29:164",
      "424:0x98720dd1925d34a2453ebc1f91c9d48e7e89ec29:181",
      "424:0x98720dd1925d34a2453ebc1f91c9d48e7e89ec29:189",
      "424:0x98720dd1925d34a2453ebc1f91c9d48e7e89ec29:158",
      "424:0x98720dd1925d34a2453ebc1f91c9d48e7e89ec29:165",
      "424:0x98720dd1925d34a2453ebc1f91c9d48e7e89ec29:173",
      "424:0x98720dd1925d34a2453ebc1f91c9d48e7e89ec29:214",
      "424:0x98720dd1925d34a2453ebc1f91c9d48e7e89ec29:179",
      "424:0x98720dd1925d34a2453ebc1f91c9d48e7e89ec29:195",
      "424:0x98720dd1925d34a2453ebc1f91c9d48e7e89ec29:171",
      "424:0x98720dd1925d34a2453ebc1f91c9d48e7e89ec29:184",
      "424:0x98720dd1925d34a2453ebc1f91c9d48e7e89ec29:205",
      "424:0x98720dd1925d34a2453ebc1f91c9d48e7e89ec29:185",
      "424:0x98720dd1925d34a2453ebc1f91c9d48e7e89ec29:219",
      "424:0x98720dd1925d34a2453ebc1f91c9d48e7e89ec29:194",
      "424:0x98720dd1925d34a2453ebc1f91c9d48e7e89ec29:209",
      "424:0x98720dd1925d34a2453ebc1f91c9d48e7e89ec29:204",
      "424:0x98720dd1925d34a2453ebc1f91c9d48e7e89ec29:227",
      "424:0x98720dd1925d34a2453ebc1f91c9d48e7e89ec29:263",
      "424:0x98720dd1925d34a2453ebc1f91c9d48e7e89ec29:236",
      "424:0x98720dd1925d34a2453ebc1f91c9d48e7e89ec29:238",
      "424:0x98720dd1925d34a2453ebc1f91c9d48e7e89ec29:224",
      "424:0xd4cc0dd193c7dc1d665ae244ce12d7fab337a008:60",
      "424:0xd4cc0dd193c7dc1d665ae244ce12d7fab337a008:52",
      "424:0xd4cc0dd193c7dc1d665ae244ce12d7fab337a008:40",
      "424:0xd4cc0dd193c7dc1d665ae244ce12d7fab337a008:22",
      "424:0xd4cc0dd193c7dc1d665ae244ce12d7fab337a008:15",
      "424:0xd4cc0dd193c7dc1d665ae244ce12d7fab337a008:122",
      "424:0xd4cc0dd193c7dc1d665ae244ce12d7fab337a008:140",
      "424:0xd4cc0dd193c7dc1d665ae244ce12d7fab337a008:136",
      "424:0xd4cc0dd193c7dc1d665ae244ce12d7fab337a008:139",
      "424:0xd4cc0dd193c7dc1d665ae244ce12d7fab337a008:120",
      "424:0xd4cc0dd193c7dc1d665ae244ce12d7fab337a008:125",
      "424:0xd4cc0dd193c7dc1d665ae244ce12d7fab337a008:131",
      "424:0xd4cc0dd193c7dc1d665ae244ce12d7fab337a008:116",
      "424:0xd4cc0dd193c7dc1d665ae244ce12d7fab337a008:114",
      "424:0xd4cc0dd193c7dc1d665ae244ce12d7fab337a008:124",
      "424:0xd4cc0dd193c7dc1d665ae244ce12d7fab337a008:118",
      "424:0xd4cc0dd193c7dc1d665ae244ce12d7fab337a008:103",
      "424:0xd4cc0dd193c7dc1d665ae244ce12d7fab337a008:105",
      "424:0xd4cc0dd193c7dc1d665ae244ce12d7fab337a008:126",
      "424:0xd4cc0dd193c7dc1d665ae244ce12d7fab337a008:117",
      "424:0xd4cc0dd193c7dc1d665ae244ce12d7fab337a008:111",
      "424:0xd4cc0dd193c7dc1d665ae244ce12d7fab337a008:104",
      "424:0xd4cc0dd193c7dc1d665ae244ce12d7fab337a008:87",
      "424:0xd4cc0dd193c7dc1d665ae244ce12d7fab337a008:86",
      "424:0xd4cc0dd193c7dc1d665ae244ce12d7fab337a008:88",
      "424:0xd4cc0dd193c7dc1d665ae244ce12d7fab337a008:77",
      "424:0xd4cc0dd193c7dc1d665ae244ce12d7fab337a008:92",
      "424:0xd4cc0dd193c7dc1d665ae244ce12d7fab337a008:93",
      "424:0xd4cc0dd193c7dc1d665ae244ce12d7fab337a008:78",
      "424:0xd4cc0dd193c7dc1d665ae244ce12d7fab337a008:72",
      "424:0xd4cc0dd193c7dc1d665ae244ce12d7fab337a008:53",
      "424:0xd4cc0dd193c7dc1d665ae244ce12d7fab337a008:75",
      "424:0xd4cc0dd193c7dc1d665ae244ce12d7fab337a008:43",
      "424:0xd4cc0dd193c7dc1d665ae244ce12d7fab337a008:71",
      "424:0xd4cc0dd193c7dc1d665ae244ce12d7fab337a008:79",
      "424:0xd4cc0dd193c7dc1d665ae244ce12d7fab337a008:74",
      "424:0xd4cc0dd193c7dc1d665ae244ce12d7fab337a008:81",
      "424:0xd4cc0dd193c7dc1d665ae244ce12d7fab337a008:44",
      "424:0xd4cc0dd193c7dc1d665ae244ce12d7fab337a008:76",
      "424:0xd4cc0dd193c7dc1d665ae244ce12d7fab337a008:51",
      "424:0xd4cc0dd193c7dc1d665ae244ce12d7fab337a008:66",
      "424:0xd4cc0dd193c7dc1d665ae244ce12d7fab337a008:70",
      "424:0xd4cc0dd193c7dc1d665ae244ce12d7fab337a008:34",
      "424:0xd4cc0dd193c7dc1d665ae244ce12d7fab337a008:57",
      "424:0xd4cc0dd193c7dc1d665ae244ce12d7fab337a008:45",
      "424:0xd4cc0dd193c7dc1d665ae244ce12d7fab337a008:42",
      "424:0xd4cc0dd193c7dc1d665ae244ce12d7fab337a008:30",
      "424:0xd4cc0dd193c7dc1d665ae244ce12d7fab337a008:32",
      "424:0xd4cc0dd193c7dc1d665ae244ce12d7fab337a008:36",
      "424:0xd4cc0dd193c7dc1d665ae244ce12d7fab337a008:28",
      "424:0xd4cc0dd193c7dc1d665ae244ce12d7fab337a008:20",
      "424:0xd4cc0dd193c7dc1d665ae244ce12d7fab337a008:1",
      "424:0xd4cc0dd193c7dc1d665ae244ce12d7fab337a008:12",
      "424:0xd4cc0dd193c7dc1d665ae244ce12d7fab337a008:5",
      "424:0xd4cc0dd193c7dc1d665ae244ce12d7fab337a008:21",
      "424:0xd4cc0dd193c7dc1d665ae244ce12d7fab337a008:3",
      "424:0xd4cc0dd193c7dc1d665ae244ce12d7fab337a008:6",
      "424:0xd4cc0dd193c7dc1d665ae244ce12d7fab337a008:65",
      "424:0xe60a569ec8aac2045d9fda306dc2a16cc1e52a90:9",
      "424:0xe60a569ec8aac2045d9fda306dc2a16cc1e52a90:4",
      "424:0xe60a569ec8aac2045d9fda306dc2a16cc1e52a90:8",
      "424:0xe60a569ec8aac2045d9fda306dc2a16cc1e52a90:19",
      "42161:0x3ac78e1ae5086904d53b41c747188216789f59a7:20",
    ],
  },
  {
    id: "devansh-mehtas-picks",
    author: "Devansh Mehta",
    name: "Devansh's picks in Climate, Web3 community & OSS",
    images: [
      "/assets/collections/collection_12.jpg",
      "/assets/collections/collection_13.jpg",
      "/assets/collections/collection_28.jpg",
      "/assets/collections/collection_14.jpg",
    ],
    description: `Selection of 60+ projects across climate round, OSS and Web3 Community and Education

  This list is based on twitter shill spaces from past Gitcoin rounds and projects I've personally met at conferences`,
    applicationRefs: [
      "10:0x5eb890e41c8d2cff75ea942085e406bb90016561:38",
      "10:0x5eb890e41c8d2cff75ea942085e406bb90016561:107",
      "10:0x5eb890e41c8d2cff75ea942085e406bb90016561:49",
      "10:0x5eb890e41c8d2cff75ea942085e406bb90016561:68",
      "10:0x5eb890e41c8d2cff75ea942085e406bb90016561:80",
      "10:0x5eb890e41c8d2cff75ea942085e406bb90016561:115",
      "10:0x5eb890e41c8d2cff75ea942085e406bb90016561:110",
      "10:0x5eb890e41c8d2cff75ea942085e406bb90016561:63",
      "10:0x5eb890e41c8d2cff75ea942085e406bb90016561:84",
      "10:0x5eb890e41c8d2cff75ea942085e406bb90016561:31",
      "10:0x5eb890e41c8d2cff75ea942085e406bb90016561:62",
      "10:0x5eb890e41c8d2cff75ea942085e406bb90016561:88",
      "10:0x5eb890e41c8d2cff75ea942085e406bb90016561:37",
      "10:0x5eb890e41c8d2cff75ea942085e406bb90016561:72",
      "424:0x98720dd1925d34a2453ebc1f91c9d48e7e89ec29:41",
      "424:0x98720dd1925d34a2453ebc1f91c9d48e7e89ec29:64",
      "424:0x98720dd1925d34a2453ebc1f91c9d48e7e89ec29:233",
      "424:0x98720dd1925d34a2453ebc1f91c9d48e7e89ec29:193",
      "424:0x98720dd1925d34a2453ebc1f91c9d48e7e89ec29:157",
      "424:0x98720dd1925d34a2453ebc1f91c9d48e7e89ec29:227",
      "424:0x98720dd1925d34a2453ebc1f91c9d48e7e89ec29:248",
      "424:0x98720dd1925d34a2453ebc1f91c9d48e7e89ec29:110",
      "424:0x98720dd1925d34a2453ebc1f91c9d48e7e89ec29:139",
      "424:0x98720dd1925d34a2453ebc1f91c9d48e7e89ec29:277",
      "424:0x98720dd1925d34a2453ebc1f91c9d48e7e89ec29:135",
      "424:0x98720dd1925d34a2453ebc1f91c9d48e7e89ec29:44",
      "424:0x98720dd1925d34a2453ebc1f91c9d48e7e89ec29:162",
      "424:0x98720dd1925d34a2453ebc1f91c9d48e7e89ec29:261",
      "424:0x98720dd1925d34a2453ebc1f91c9d48e7e89ec29:177",
      "424:0x98720dd1925d34a2453ebc1f91c9d48e7e89ec29:231",
      "424:0x98720dd1925d34a2453ebc1f91c9d48e7e89ec29:42",
      "424:0x98720dd1925d34a2453ebc1f91c9d48e7e89ec29:127",
      "424:0x98720dd1925d34a2453ebc1f91c9d48e7e89ec29:222",
      "424:0x98720dd1925d34a2453ebc1f91c9d48e7e89ec29:71",
      "424:0x98720dd1925d34a2453ebc1f91c9d48e7e89ec29:128",
      "424:0x98720dd1925d34a2453ebc1f91c9d48e7e89ec29:262",
      "424:0x98720dd1925d34a2453ebc1f91c9d48e7e89ec29:180",
      "424:0x98720dd1925d34a2453ebc1f91c9d48e7e89ec29:253",
      "424:0x98720dd1925d34a2453ebc1f91c9d48e7e89ec29:280",
      "424:0x98720dd1925d34a2453ebc1f91c9d48e7e89ec29:20",
      "424:0xd4cc0dd193c7dc1d665ae244ce12d7fab337a008:129",
      "424:0xd4cc0dd193c7dc1d665ae244ce12d7fab337a008:114",
      "424:0xd4cc0dd193c7dc1d665ae244ce12d7fab337a008:11",
      "424:0xd4cc0dd193c7dc1d665ae244ce12d7fab337a008:83",
      "424:0xd4cc0dd193c7dc1d665ae244ce12d7fab337a008:57",
      "424:0xd4cc0dd193c7dc1d665ae244ce12d7fab337a008:34",
      "424:0xd4cc0dd193c7dc1d665ae244ce12d7fab337a008:94",
      "424:0xd4cc0dd193c7dc1d665ae244ce12d7fab337a008:94",
      "424:0xd4cc0dd193c7dc1d665ae244ce12d7fab337a008:98",
      "424:0xd4cc0dd193c7dc1d665ae244ce12d7fab337a008:46",
      "424:0xd4cc0dd193c7dc1d665ae244ce12d7fab337a008:103",
      "424:0xd4cc0dd193c7dc1d665ae244ce12d7fab337a008:78",
      "424:0xd4cc0dd193c7dc1d665ae244ce12d7fab337a008:68",
      "424:0xd4cc0dd193c7dc1d665ae244ce12d7fab337a008:22",
      "424:0xd4cc0dd193c7dc1d665ae244ce12d7fab337a008:32",
      "424:0xd4cc0dd193c7dc1d665ae244ce12d7fab337a008:49",
      "424:0xd4cc0dd193c7dc1d665ae244ce12d7fab337a008:25",
      "424:0xd4cc0dd193c7dc1d665ae244ce12d7fab337a008:62",
      "424:0xd4cc0dd193c7dc1d665ae244ce12d7fab337a008:132",
      "424:0xd4cc0dd193c7dc1d665ae244ce12d7fab337a008:85",
    ],
  },
  {
    id: "mxweb3",
    author: "meximalist.eth",
    name: "MxWeb3 GG19 Collection",
    images: [
      "https://d16c97c2np8a2o.cloudfront.net/ipfs/bafkreidmqlhmx5b62ar7cp4ih3ceksczzzyd6koqg5kzoyu7eigjnr6o6u?img-height=640",
      "https://d16c97c2np8a2o.cloudfront.net/ipfs/bafkreihigxg5bfz72jjn4boiu6xdxfnbrdgueifj4km72u7kgy7so2zgve?img-height=640",
      "https://d16c97c2np8a2o.cloudfront.net/ipfs/bafybeibijdo5evy4vn5hcnuyb5ecvbrabqioxqrk2emzcmlf365ehyrfdq?img-height=640",
      "https://d16c97c2np8a2o.cloudfront.net/ipfs/bafkreifalsv34nge6of5st7eba4z4g6fg4ughqw5g7gpovy2vm6ukl23iq?img-height=640",
    ],
    description: `I curated the MxWeb3 GG19 Collection list with projects that I have had contact with. I know their team personally or have talked with them in many spaces. My list is mostly LATAM projects that are building and doing cool things. LATAM is one of the best markets to onboard millions in the next few years.`,
    applicationRefs: [
      "424:0x98720dd1925d34a2453ebc1f91c9d48e7e89ec29:140",
      "424:0x98720dd1925d34a2453ebc1f91c9d48e7e89ec29:93",
      "424:0x98720dd1925d34a2453ebc1f91c9d48e7e89ec29:146",
      "424:0x98720dd1925d34a2453ebc1f91c9d48e7e89ec29:208",
      "424:0x98720dd1925d34a2453ebc1f91c9d48e7e89ec29:184",
      "424:0x98720dd1925d34a2453ebc1f91c9d48e7e89ec29:212",
      "424:0x98720dd1925d34a2453ebc1f91c9d48e7e89ec29:54",
      "10:0x5eb890e41c8d2cff75ea942085e406bb90016561:107",
      "42161:0xe168ac27b7c32db85478a6807640c8bca1220d15:5",
      "424:0x98720dd1925d34a2453ebc1f91c9d48e7e89ec29:261",
      "424:0x98720dd1925d34a2453ebc1f91c9d48e7e89ec29:63",
      "424:0x98720dd1925d34a2453ebc1f91c9d48e7e89ec29:254",
      "10:0x5eb890e41c8d2cff75ea942085e406bb90016561:87",
      "424:0x98720dd1925d34a2453ebc1f91c9d48e7e89ec29:103",
      "424:0x98720dd1925d34a2453ebc1f91c9d48e7e89ec29:144",
      "42161:0xe168ac27b7c32db85478a6807640c8bca1220d15:7",
      "10:0x5eb890e41c8d2cff75ea942085e406bb90016561:60",
      "424:0x98720dd1925d34a2453ebc1f91c9d48e7e89ec29:34",
      "424:0x98720dd1925d34a2453ebc1f91c9d48e7e89ec29:181",
      "424:0x98720dd1925d34a2453ebc1f91c9d48e7e89ec29:92",
      "424:0x98720dd1925d34a2453ebc1f91c9d48e7e89ec29:78",
      "424:0x98720dd1925d34a2453ebc1f91c9d48e7e89ec29:214",
      "424:0x98720dd1925d34a2453ebc1f91c9d48e7e89ec29:2",
      "10:0x5eb890e41c8d2cff75ea942085e406bb90016561:16",
      "424:0x98720dd1925d34a2453ebc1f91c9d48e7e89ec29:174",
      "42161:0xe168ac27b7c32db85478a6807640c8bca1220d15:9",
      "42161:0xe168ac27b7c32db85478a6807640c8bca1220d15:0",
      "42161:0xe168ac27b7c32db85478a6807640c8bca1220d15:6",
    ],
  },
  {
    id: "intro-to-the-gitcoinverse",
    author: "CryptoReuMD",
    name: "Intro to the Gitcoinverse",
    images: [
      "/assets/collections/collection_19.jpg",
      "/assets/collections/collection_18.jpg",
      "/assets/collections/collection_30.jpg",
      "/assets/collections/collection_20.jpg",
    ],
    description: `This is from my heart to all the ReFi projects that are building some meaningful initiatives to our lives, and the ecosystem.`,
    applicationRefs: [
      "10:0x5eb890e41c8d2cff75ea942085e406bb90016561:43",
      "10:0xc34745b3852df32d5958be88df2bee0a83474001:25",
      "10:0x5eb890e41c8d2cff75ea942085e406bb90016561:38",
      "10:0xc34745b3852df32d5958be88df2bee0a83474001:23",
      "424:0x98720dd1925d34a2453ebc1f91c9d48e7e89ec29:35",
      "137:0xa1d52f9b5339792651861329a046dd912761e9a9:3",
      "137:0xa1d52f9b5339792651861329a046dd912761e9a9:9",
      "10:0x5eb890e41c8d2cff75ea942085e406bb90016561:70",
      "10:0x5eb890e41c8d2cff75ea942085e406bb90016561:97",
      "424:0xe60a569ec8aac2045d9fda306dc2a16cc1e52a90:16",
      "424:0x98720dd1925d34a2453ebc1f91c9d48e7e89ec29:62",
      "424:0x98720dd1925d34a2453ebc1f91c9d48e7e89ec29:191",
      "10:0x5eb890e41c8d2cff75ea942085e406bb90016561:76",
      "10:0x5eb890e41c8d2cff75ea942085e406bb90016561:96",
      "10:0x5eb890e41c8d2cff75ea942085e406bb90016561:34",
      "137:0xa1d52f9b5339792651861329a046dd912761e9a9:1",
      "424:0x98720dd1925d34a2453ebc1f91c9d48e7e89ec29:239",
      "424:0x98720dd1925d34a2453ebc1f91c9d48e7e89ec29:11",
      "137:0xa1d52f9b5339792651861329a046dd912761e9a9:4",
      "424:0x98720dd1925d34a2453ebc1f91c9d48e7e89ec29:44",
      "10:0x5eb890e41c8d2cff75ea942085e406bb90016561:63",
      "424:0xd4cc0dd193c7dc1d665ae244ce12d7fab337a008:87",
      "424:0xd4cc0dd193c7dc1d665ae244ce12d7fab337a008:127",
      "424:0x98720dd1925d34a2453ebc1f91c9d48e7e89ec29:127",
      "424:0x98720dd1925d34a2453ebc1f91c9d48e7e89ec29:209",
      "424:0x98720dd1925d34a2453ebc1f91c9d48e7e89ec29:277",
      "424:0x98720dd1925d34a2453ebc1f91c9d48e7e89ec29:264",
      "424:0x98720dd1925d34a2453ebc1f91c9d48e7e89ec29:38",
      "424:0xd4cc0dd193c7dc1d665ae244ce12d7fab337a008:25",
      "424:0x98720dd1925d34a2453ebc1f91c9d48e7e89ec29:155",
      "10:0x5eb890e41c8d2cff75ea942085e406bb90016561:88",
      "42161:0xe168ac27b7c32db85478a6807640c8bca1220d15:6",
      "424:0x98720dd1925d34a2453ebc1f91c9d48e7e89ec29:78",
      "10:0x36f548e082b09b0cec5b3f5a7b78953c75de5e74:5",
      "10:0x5eb890e41c8d2cff75ea942085e406bb90016561:39",
      "424:0xe60a569ec8aac2045d9fda306dc2a16cc1e52a90:11",
      "424:0xd4cc0dd193c7dc1d665ae244ce12d7fab337a008:12",
      "424:0xd4cc0dd193c7dc1d665ae244ce12d7fab337a008:6",
      "424:0xd4cc0dd193c7dc1d665ae244ce12d7fab337a008:132",
      "424:0x98720dd1925d34a2453ebc1f91c9d48e7e89ec29:228",
      "424:0x98720dd1925d34a2453ebc1f91c9d48e7e89ec29:32",
      "10:0x5eb890e41c8d2cff75ea942085e406bb90016561:35",
      "10:0x5eb890e41c8d2cff75ea942085e406bb90016561:68",
      "10:0x5eb890e41c8d2cff75ea942085e406bb90016561:113",
      "424:0xd4cc0dd193c7dc1d665ae244ce12d7fab337a008:37",
      "10:0xc34745b3852df32d5958be88df2bee0a83474001:7",
      "10:0x5eb890e41c8d2cff75ea942085e406bb90016561:79",
      "10:0x5eb890e41c8d2cff75ea942085e406bb90016561:110",
      "424:0xd4cc0dd193c7dc1d665ae244ce12d7fab337a008:28",
      "424:0xd4cc0dd193c7dc1d665ae244ce12d7fab337a008:75",
      "10:0x5eb890e41c8d2cff75ea942085e406bb90016561:44",
      "10:0x5eb890e41c8d2cff75ea942085e406bb90016561:107",
      "424:0x98720dd1925d34a2453ebc1f91c9d48e7e89ec29:187",
      "10:0x5eb890e41c8d2cff75ea942085e406bb90016561:20",
      "424:0x98720dd1925d34a2453ebc1f91c9d48e7e89ec29:262",
      "424:0xd4cc0dd193c7dc1d665ae244ce12d7fab337a008:84",
      "10:0x5eb890e41c8d2cff75ea942085e406bb90016561:72",
      "10:0x5eb890e41c8d2cff75ea942085e406bb90016561:0",
      "10:0x5eb890e41c8d2cff75ea942085e406bb90016561:50",
      "10:0x5eb890e41c8d2cff75ea942085e406bb90016561:52",
      "10:0x5eb890e41c8d2cff75ea942085e406bb90016561:55",
      "10:0x5eb890e41c8d2cff75ea942085e406bb90016561:37",
      "10:0x5eb890e41c8d2cff75ea942085e406bb90016561:22",
      "10:0x5eb890e41c8d2cff75ea942085e406bb90016561:86",
      "10:0x5eb890e41c8d2cff75ea942085e406bb90016561:23",
      "424:0x98720dd1925d34a2453ebc1f91c9d48e7e89ec29:55",
      "424:0x98720dd1925d34a2453ebc1f91c9d48e7e89ec29:231",
      "424:0x98720dd1925d34a2453ebc1f91c9d48e7e89ec29:53",
      "424:0x98720dd1925d34a2453ebc1f91c9d48e7e89ec29:150",
      "424:0x98720dd1925d34a2453ebc1f91c9d48e7e89ec29:54",
      "424:0x98720dd1925d34a2453ebc1f91c9d48e7e89ec29:26",
      "424:0x98720dd1925d34a2453ebc1f91c9d48e7e89ec29:166",
      "424:0x98720dd1925d34a2453ebc1f91c9d48e7e89ec29:137",
      "424:0x98720dd1925d34a2453ebc1f91c9d48e7e89ec29:186",
      "424:0x98720dd1925d34a2453ebc1f91c9d48e7e89ec29:218",
      "424:0x98720dd1925d34a2453ebc1f91c9d48e7e89ec29:271",
      "424:0x98720dd1925d34a2453ebc1f91c9d48e7e89ec29:190",
      "424:0x98720dd1925d34a2453ebc1f91c9d48e7e89ec29:147",
      "424:0x98720dd1925d34a2453ebc1f91c9d48e7e89ec29:104",
      "424:0x98720dd1925d34a2453ebc1f91c9d48e7e89ec29:61",
      "424:0x98720dd1925d34a2453ebc1f91c9d48e7e89ec29:148",
      "424:0x98720dd1925d34a2453ebc1f91c9d48e7e89ec29:175",
      "424:0x98720dd1925d34a2453ebc1f91c9d48e7e89ec29:133",
      "424:0x98720dd1925d34a2453ebc1f91c9d48e7e89ec29:144",
    ],
  },
  {
    id: "stake-from-home",
    author: "superphiz",
    name: "The #StakeFromHome Collection - Empowering Home Stakers",
    images: [
      "/assets/collections/collection_09.jpg",
      "/assets/collections/collection_10.jpg",
      "/assets/collections/collection_27.jpg",
      "/assets/collections/collection_11.jpg",
    ],
    description:
      "The #StakeFromHome collection focuses on resources that educate, support, and promote home staking on the Ethereum network. I believe Ethereum will be most successful when we decentralize with nodes and validators in homes all over the world. I hand picked these projects because of my knowledge of their existing contributions and I'm excited for all of us to support their work.",
    applicationRefs: [
      "137:0xa1d52f9b5339792651861329a046dd912761e9a9:1",
      "424:0xd4cc0dd193c7dc1d665ae244ce12d7fab337a008:61",
      "424:0x98720dd1925d34a2453ebc1f91c9d48e7e89ec29:14",
      "424:0xd4cc0dd193c7dc1d665ae244ce12d7fab337a008:20",
      "137:0xa1d52f9b5339792651861329a046dd912761e9a9:3",
      "137:0xa1d52f9b5339792651861329a046dd912761e9a9:8",
      "137:0xa1d52f9b5339792651861329a046dd912761e9a9:10",
      "424:0xd4cc0dd193c7dc1d665ae244ce12d7fab337a008:65",
      "137:0xa1d52f9b5339792651861329a046dd912761e9a9:2",
      "137:0xa1d52f9b5339792651861329a046dd912761e9a9:14",
      "137:0xa1d52f9b5339792651861329a046dd912761e9a9:6",
      "424:0x98720dd1925d34a2453ebc1f91c9d48e7e89ec29:109",
      "10:0x36f548e082b09b0cec5b3f5a7b78953c75de5e74:8",
      "137:0xa1d52f9b5339792651861329a046dd912761e9a9:4",
    ],
  },

  {
    id: "innovators-champions",
    author: "olimpio.eth",
    name: "Innovators' Champions Collection",
    images: [
      "/assets/collections/collection_21.jpg",
      "/assets/collections/collection_23.jpg",
      "/assets/collections/collection_31.jpg",
      "/assets/collections/collection_22.jpg",
    ],
    description: `A selection of builders, normal users, amazing tools and different organizations that continuously contribute to the blockchain ecosystem.`,
    applicationRefs: [
      "424:0x98720dd1925d34a2453ebc1f91c9d48e7e89ec29:262",
      "424:0x98720dd1925d34a2453ebc1f91c9d48e7e89ec29:250",
      "424:0x98720dd1925d34a2453ebc1f91c9d48e7e89ec29:222",
      "424:0xd4cc0dd193c7dc1d665ae244ce12d7fab337a008:10",
      "424:0xd4cc0dd193c7dc1d665ae244ce12d7fab337a008:139",
      "424:0xd4cc0dd193c7dc1d665ae244ce12d7fab337a008:28",
      "424:0xd4cc0dd193c7dc1d665ae244ce12d7fab337a008:68",
      "424:0xd4cc0dd193c7dc1d665ae244ce12d7fab337a008:4",
      "137:0xa1d52f9b5339792651861329a046dd912761e9a9:1",
      "137:0xa1d52f9b5339792651861329a046dd912761e9a9:15",
      "137:0xa1d52f9b5339792651861329a046dd912761e9a9:3",
      "137:0xa1d52f9b5339792651861329a046dd912761e9a9:4",
      "42161:0x3ac78e1ae5086904d53b41c747188216789f59a7:22",
      "424:0x98720dd1925d34a2453ebc1f91c9d48e7e89ec29:246",
      "424:0x98720dd1925d34a2453ebc1f91c9d48e7e89ec29:70",
      "424:0xd4cc0dd193c7dc1d665ae244ce12d7fab337a008:78",
      "424:0xd4cc0dd193c7dc1d665ae244ce12d7fab337a008:29",
      "424:0xd4cc0dd193c7dc1d665ae244ce12d7fab337a008:6",
      "424:0xd4cc0dd193c7dc1d665ae244ce12d7fab337a008:103",
      "424:0xd4cc0dd193c7dc1d665ae244ce12d7fab337a008:128",
      "137:0xa1d52f9b5339792651861329a046dd912761e9a9:7",
      "137:0xa1d52f9b5339792651861329a046dd912761e9a9:10",
      "42161:0x3ac78e1ae5086904d53b41c747188216789f59a7:25",
      "42161:0x3ac78e1ae5086904d53b41c747188216789f59a7:19",
      "42161:0x3ac78e1ae5086904d53b41c747188216789f59a7:15",
      "42161:0x3ac78e1ae5086904d53b41c747188216789f59a7:8",
      "137:0xa1d52f9b5339792651861329a046dd912761e9a9:14",
      "137:0xa1d52f9b5339792651861329a046dd912761e9a9:6",
      "424:0xd4cc0dd193c7dc1d665ae244ce12d7fab337a008:62",
      "424:0x98720dd1925d34a2453ebc1f91c9d48e7e89ec29:154",
      "424:0xd4cc0dd193c7dc1d665ae244ce12d7fab337a008:14",
      "42161:0xa7608d95a93cc684f2719323d40cbd0f59afe7d4:1",
    ],
  },

  {
    id: "evergreen-list",
    author: "Wasabi",
    name: "The Evergreen List",
    images: [
      "/assets/collections/collection_24.jpg",
      "/assets/collections/collection_26.jpg",
      "/assets/collections/collection_32.jpg",
      "/assets/collections/collection_25.jpg",
    ],
    description: `List of grantees that IMO are building sustainable and with the possibility of becoming a perpetual source of value for the wider community.`,
    applicationRefs: [
      "10:0x5eb890e41c8d2cff75ea942085e406bb90016561:110",
      "10:0x5eb890e41c8d2cff75ea942085e406bb90016561:31",
      "10:0x5eb890e41c8d2cff75ea942085e406bb90016561:114",
      "10:0x5eb890e41c8d2cff75ea942085e406bb90016561:72",
      "10:0x5eb890e41c8d2cff75ea942085e406bb90016561:60",
      "10:0x5eb890e41c8d2cff75ea942085e406bb90016561:98",
      "10:0x5eb890e41c8d2cff75ea942085e406bb90016561:49",
      "10:0x5eb890e41c8d2cff75ea942085e406bb90016561:88",
      "10:0x5eb890e41c8d2cff75ea942085e406bb90016561:63",
      "10:0x5eb890e41c8d2cff75ea942085e406bb90016561:85",
      "10:0x5eb890e41c8d2cff75ea942085e406bb90016561:89",
      "424:0x98720dd1925d34a2453ebc1f91c9d48e7e89ec29:157",
      "424:0x98720dd1925d34a2453ebc1f91c9d48e7e89ec29:72",
      "424:0x98720dd1925d34a2453ebc1f91c9d48e7e89ec29:202",
      "424:0x98720dd1925d34a2453ebc1f91c9d48e7e89ec29:110",
      "424:0x98720dd1925d34a2453ebc1f91c9d48e7e89ec29:296",
      "424:0x98720dd1925d34a2453ebc1f91c9d48e7e89ec29:15",
      "424:0x98720dd1925d34a2453ebc1f91c9d48e7e89ec29:208",
      "424:0x98720dd1925d34a2453ebc1f91c9d48e7e89ec29:277",
      "424:0x98720dd1925d34a2453ebc1f91c9d48e7e89ec29:148",
      "424:0x98720dd1925d34a2453ebc1f91c9d48e7e89ec29:247",
      "424:0x98720dd1925d34a2453ebc1f91c9d48e7e89ec29:32",
      "424:0x98720dd1925d34a2453ebc1f91c9d48e7e89ec29:128",
      "424:0x98720dd1925d34a2453ebc1f91c9d48e7e89ec29:46",
      "424:0x98720dd1925d34a2453ebc1f91c9d48e7e89ec29:196",
      "424:0x98720dd1925d34a2453ebc1f91c9d48e7e89ec29:223",
      "424:0x98720dd1925d34a2453ebc1f91c9d48e7e89ec29:137",
      "424:0xd4cc0dd193c7dc1d665ae244ce12d7fab337a008:62",
      "424:0xd4cc0dd193c7dc1d665ae244ce12d7fab337a008:39",
      "424:0xd4cc0dd193c7dc1d665ae244ce12d7fab337a008:46",
      "424:0xd4cc0dd193c7dc1d665ae244ce12d7fab337a008:22",
      "424:0xd4cc0dd193c7dc1d665ae244ce12d7fab337a008:28",
      "424:0xd4cc0dd193c7dc1d665ae244ce12d7fab337a008:30",
      "424:0xd4cc0dd193c7dc1d665ae244ce12d7fab337a008:68",
      "424:0xd4cc0dd193c7dc1d665ae244ce12d7fab337a008:167",
      "424:0xd4cc0dd193c7dc1d665ae244ce12d7fab337a008:42",
      "424:0x98720dd1925d34a2453ebc1f91c9d48e7e89ec29:231",
      "10:0x5eb890e41c8d2cff75ea942085e406bb90016561:39",
      "424:0x98720dd1925d34a2453ebc1f91c9d48e7e89ec29:160",
      "424:0xd4cc0dd193c7dc1d665ae244ce12d7fab337a008:25",
      "424:0x98720dd1925d34a2453ebc1f91c9d48e7e89ec29:172",
      "10:0x5eb890e41c8d2cff75ea942085e406bb90016561:101",
      "10:0x5eb890e41c8d2cff75ea942085e406bb90016561:22",
    ],
  },

  {
    id: "the-midnight-gospel",
    author: "Ale RaMo",
    name: "The Midnight Gospel",
    images: [
      "/assets/collections/collection_15.jpg",
      "/assets/collections/collection_16.jpg",
      "/assets/collections/collection_29.jpg",
      "/assets/collections/collection_17.jpg",
    ],
    description: `I've centered my attention on projects that not only deliver genuine impact but also contribute tangible value to the ecosystem. My focus remains steadfast on initiatives that make a meaningful difference.

  ""It's not about ideas. It's about making ideas happen."" - Scott Belsky`,
    applicationRefs: [
      "424:0x98720dd1925d34a2453ebc1f91c9d48e7e89ec29:184",
      "424:0x98720dd1925d34a2453ebc1f91c9d48e7e89ec29:92",
      "424:0x98720dd1925d34a2453ebc1f91c9d48e7e89ec29:181",
      "424:0x98720dd1925d34a2453ebc1f91c9d48e7e89ec29:54",
      "424:0x98720dd1925d34a2453ebc1f91c9d48e7e89ec29:208",
      "424:0x98720dd1925d34a2453ebc1f91c9d48e7e89ec29:144",
      "424:0x98720dd1925d34a2453ebc1f91c9d48e7e89ec29:93",
      "424:0x98720dd1925d34a2453ebc1f91c9d48e7e89ec29:63",
      "424:0x98720dd1925d34a2453ebc1f91c9d48e7e89ec29:17",
      "424:0x98720dd1925d34a2453ebc1f91c9d48e7e89ec29:103",
      "424:0x98720dd1925d34a2453ebc1f91c9d48e7e89ec29:30",
      "424:0x98720dd1925d34a2453ebc1f91c9d48e7e89ec29:164",
      "424:0x98720dd1925d34a2453ebc1f91c9d48e7e89ec29:214",
      "424:0x98720dd1925d34a2453ebc1f91c9d48e7e89ec29:52",
      "42161:0xa7608d95a93cc684f2719323d40cbd0f59afe7d4:2",
      "10:0xc34745b3852df32d5958be88df2bee0a83474001:16",
      "424:0x98720dd1925d34a2453ebc1f91c9d48e7e89ec29:193",
      "424:0x98720dd1925d34a2453ebc1f91c9d48e7e89ec29:59",
      "42161:0x0f0b9d9f72c1660905c57864e79ceb409ada0c9e:22",
      "424:0x98720dd1925d34a2453ebc1f91c9d48e7e89ec29:212",
      "10:0xc34745b3852df32d5958be88df2bee0a83474001:6",
      "10:0xc34745b3852df32d5958be88df2bee0a83474001:5",
      "10:0x6726fe9c89fb04eaef388c11cf55be6aa0a62fb9:26",
      "424:0xd4cc0dd193c7dc1d665ae244ce12d7fab337a008:62",
      "424:0xd4cc0dd193c7dc1d665ae244ce12d7fab337a008:64",
      "424:0xd4cc0dd193c7dc1d665ae244ce12d7fab337a008:120",
      "10:0xc34745b3852df32d5958be88df2bee0a83474001:8",
      "424:0xd4cc0dd193c7dc1d665ae244ce12d7fab337a008:84",
      "424:0xd4cc0dd193c7dc1d665ae244ce12d7fab337a008:93",
      "424:0xd4cc0dd193c7dc1d665ae244ce12d7fab337a008:52",
      "424:0xd4cc0dd193c7dc1d665ae244ce12d7fab337a008:37",
      "424:0xd4cc0dd193c7dc1d665ae244ce12d7fab337a008:26",
      "424:0xd4cc0dd193c7dc1d665ae244ce12d7fab337a008:63",
      "137:0xa1d52f9b5339792651861329a046dd912761e9a9:7",
      "137:0xa1d52f9b5339792651861329a046dd912761e9a9:9",
      "137:0xa1d52f9b5339792651861329a046dd912761e9a9:11",
      "137:0xa1d52f9b5339792651861329a046dd912761e9a9:14",
      "137:0xa1d52f9b5339792651861329a046dd912761e9a9:8",
      "137:0xa1d52f9b5339792651861329a046dd912761e9a9:0",
      "137:0xa1d52f9b5339792651861329a046dd912761e9a9:16",
      "137:0xa1d52f9b5339792651861329a046dd912761e9a9:4",
      "137:0xa1d52f9b5339792651861329a046dd912761e9a9:3",
      "137:0xa1d52f9b5339792651861329a046dd912761e9a9:17",
      "137:0xa1d52f9b5339792651861329a046dd912761e9a9:5",
      "10:0x5eb890e41c8d2cff75ea942085e406bb90016561:107",
      "10:0x5eb890e41c8d2cff75ea942085e406bb90016561:43",
      "10:0x5eb890e41c8d2cff75ea942085e406bb90016561:87",
      "10:0x5eb890e41c8d2cff75ea942085e406bb90016561:33",
      "10:0x5eb890e41c8d2cff75ea942085e406bb90016561:44",
      "10:0x5eb890e41c8d2cff75ea942085e406bb90016561:20",
      "10:0x5eb890e41c8d2cff75ea942085e406bb90016561:54",
      "10:0x5eb890e41c8d2cff75ea942085e406bb90016561:49",
      "10:0x5eb890e41c8d2cff75ea942085e406bb90016561:31",
      "10:0x5eb890e41c8d2cff75ea942085e406bb90016561:72",
      "424:0xe60a569ec8aac2045d9fda306dc2a16cc1e52a90:19",
      "10:0x5eb890e41c8d2cff75ea942085e406bb90016561:16",
      "10:0x5eb890e41c8d2cff75ea942085e406bb90016561:40",
      "10:0x5eb890e41c8d2cff75ea942085e406bb90016561:110",
      "10:0x5eb890e41c8d2cff75ea942085e406bb90016561:64",
      "10:0xc34745b3852df32d5958be88df2bee0a83474001:16",
      "10:0x5eb890e41c8d2cff75ea942085e406bb90016561:48",
      "10:0x5eb890e41c8d2cff75ea942085e406bb90016561:50",
      "42161:0x3ac78e1ae5086904d53b41c747188216789f59a7:19",
      "42161:0x3ac78e1ae5086904d53b41c747188216789f59a7:20",
      "42161:0x3ac78e1ae5086904d53b41c747188216789f59a7:15",
      "42161:0x3ac78e1ae5086904d53b41c747188216789f59a7:16",
      "42161:0x3ac78e1ae5086904d53b41c747188216789f59a7:27",
      "42161:0x3ac78e1ae5086904d53b41c747188216789f59a7:9",
      "42161:0x0f0b9d9f72c1660905c57864e79ceb409ada0c9e:8",
      "42161:0x0f0b9d9f72c1660905c57864e79ceb409ada0c9e:21",
      "42161:0x0f0b9d9f72c1660905c57864e79ceb409ada0c9e:7",
      "10:0x6726fe9c89fb04eaef388c11cf55be6aa0a62fb9:25",
      "42161:0xa7608d95a93cc684f2719323d40cbd0f59afe7d4:2",
      "424:0xe60a569ec8aac2045d9fda306dc2a16cc1e52a90:6",
      "424:0xe60a569ec8aac2045d9fda306dc2a16cc1e52a90:5",
      "424:0xe60a569ec8aac2045d9fda306dc2a16cc1e52a90:2",
      "10:0xc34745b3852df32d5958be88df2bee0a83474001:16",
      "424:0xe60a569ec8aac2045d9fda306dc2a16cc1e52a90:23",
      "424:0xe60a569ec8aac2045d9fda306dc2a16cc1e52a90:19",
      "42161:0xa7608d95a93cc684f2719323d40cbd0f59afe7d4:2",
      "42161:0xa7608d95a93cc684f2719323d40cbd0f59afe7d4:10",
      "10:0xc34745b3852df32d5958be88df2bee0a83474001:16",
      "10:0xc34745b3852df32d5958be88df2bee0a83474001:14",
      "10:0xc34745b3852df32d5958be88df2bee0a83474001:19",
      "10:0xc34745b3852df32d5958be88df2bee0a83474001:9",
    ],
  },

  {
    id: "carls-collection",
    author: "cerv1.eth",
    name: "Carl's Collection",
    images: [
      "https://d16c97c2np8a2o.cloudfront.net/ipfs/bafybeidh22fvqahrapg4j5nuzkmsox3nrcghgqdkjn6zdvvaswaglh3b34?img-height=640",
      "https://d16c97c2np8a2o.cloudfront.net/ipfs/bafkreih4uwhpqg2negucffp3hp73ofvtzabv5nknrfdylxzxkxlvrn275y?img-height=640",
      "https://d16c97c2np8a2o.cloudfront.net/ipfs/bafkreibbzla5hhompjod7mdjdruay7kqblwan3umokuthdt4kl3qtnk4uy?img-height=640",
      "https://d16c97c2np8a2o.cloudfront.net/ipfs/bafybeid7zl7464mjhrgiwk6g5ljz2jh3giw3qsp732fvgz7dtoyx3kmdoq?img-height=640",
    ],
    description: `My goal was to highlight projects that have (a) been valuable to me in some way, and (b) are not front runners in any of the rounds so far. I took a snapshot of projects on the morning of Nov 17 and set a popularity threshold. I only considered projects below that threshold, and capped my picks to no more than 10% of the total projects in a given round.`,
    applicationRefs: [
      "137:0xa1d52f9b5339792651861329a046dd912761e9a9:13",
      "137:0xa1d52f9b5339792651861329a046dd912761e9a9:20",
      "424:0x98720dd1925d34a2453ebc1f91c9d48e7e89ec29:127",
      "424:0x98720dd1925d34a2453ebc1f91c9d48e7e89ec29:150",
      "424:0x98720dd1925d34a2453ebc1f91c9d48e7e89ec29:223",
      "424:0x98720dd1925d34a2453ebc1f91c9d48e7e89ec29:277",
      "424:0x98720dd1925d34a2453ebc1f91c9d48e7e89ec29:128",
      "424:0x98720dd1925d34a2453ebc1f91c9d48e7e89ec29:167",
      "424:0x98720dd1925d34a2453ebc1f91c9d48e7e89ec29:44",
      "424:0x98720dd1925d34a2453ebc1f91c9d48e7e89ec29:157",
      "424:0x98720dd1925d34a2453ebc1f91c9d48e7e89ec29:231",
      "424:0x98720dd1925d34a2453ebc1f91c9d48e7e89ec29:246",
      "424:0x98720dd1925d34a2453ebc1f91c9d48e7e89ec29:32",
      "424:0x98720dd1925d34a2453ebc1f91c9d48e7e89ec29:139",
      "424:0x98720dd1925d34a2453ebc1f91c9d48e7e89ec29:125",
      "424:0x98720dd1925d34a2453ebc1f91c9d48e7e89ec29:148",
      "424:0x98720dd1925d34a2453ebc1f91c9d48e7e89ec29:225",
      "424:0x98720dd1925d34a2453ebc1f91c9d48e7e89ec29:162",
      "424:0x98720dd1925d34a2453ebc1f91c9d48e7e89ec29:89",
      "424:0x98720dd1925d34a2453ebc1f91c9d48e7e89ec29:183",
      "424:0x98720dd1925d34a2453ebc1f91c9d48e7e89ec29:243",
      "424:0x98720dd1925d34a2453ebc1f91c9d48e7e89ec29:233",
      "424:0x98720dd1925d34a2453ebc1f91c9d48e7e89ec29:110",
      "424:0x98720dd1925d34a2453ebc1f91c9d48e7e89ec29:99",
      "424:0x98720dd1925d34a2453ebc1f91c9d48e7e89ec29:135",
      "424:0x98720dd1925d34a2453ebc1f91c9d48e7e89ec29:84",
      "424:0x98720dd1925d34a2453ebc1f91c9d48e7e89ec29:193",
      "424:0x98720dd1925d34a2453ebc1f91c9d48e7e89ec29:306",
      "424:0xd4cc0dd193c7dc1d665ae244ce12d7fab337a008:28",
      "424:0xd4cc0dd193c7dc1d665ae244ce12d7fab337a008:81",
      "424:0xd4cc0dd193c7dc1d665ae244ce12d7fab337a008:68",
      "424:0xd4cc0dd193c7dc1d665ae244ce12d7fab337a008:45",
      "424:0xd4cc0dd193c7dc1d665ae244ce12d7fab337a008:52",
      "424:0xd4cc0dd193c7dc1d665ae244ce12d7fab337a008:98",
      "424:0xd4cc0dd193c7dc1d665ae244ce12d7fab337a008:11",
      "424:0xd4cc0dd193c7dc1d665ae244ce12d7fab337a008:129",
      "424:0xd4cc0dd193c7dc1d665ae244ce12d7fab337a008:83",
      "424:0xd4cc0dd193c7dc1d665ae244ce12d7fab337a008:101",
      "424:0xd4cc0dd193c7dc1d665ae244ce12d7fab337a008:22",
      "424:0xd4cc0dd193c7dc1d665ae244ce12d7fab337a008:169",
      "10:0x5eb890e41c8d2cff75ea942085e406bb90016561:80",
      "10:0x5eb890e41c8d2cff75ea942085e406bb90016561:110",
      "10:0x5eb890e41c8d2cff75ea942085e406bb90016561:69",
      "10:0x5eb890e41c8d2cff75ea942085e406bb90016561:63",
      "10:0x5eb890e41c8d2cff75ea942085e406bb90016561:38",
      "10:0x5eb890e41c8d2cff75ea942085e406bb90016561:107",
      "10:0x5eb890e41c8d2cff75ea942085e406bb90016561:13",
      "10:0xc34745b3852df32d5958be88df2bee0a83474001:16",
      "10:0x5eb890e41c8d2cff75ea942085e406bb90016561:11",
      "424:0xd4cc0dd193c7dc1d665ae244ce12d7fab337a008:70",
    ],
  },

  {
    id: "giveth-galaxy",
    author: "Griff",
    name: "Giveth Galaxy",
    images: [
      "https://d16c97c2np8a2o.cloudfront.net/ipfs/bafkreifmllagjfloblieklder7xv56cqdtdaw3zydms6n7nwjhzw3byrte?img-height=640",
      "https://d16c97c2np8a2o.cloudfront.net/ipfs/bafkreiapdoywnlm6ug3szknamydm3b2q3rqxsk2jgnc7pwb5wgdk4kw2mm?img-height=640",
      "https://d16c97c2np8a2o.cloudfront.net/ipfs/bafkreiea37cwpqtzbkcr7lwrxkwwdtshxcuzxegmep5cizck7iqqmoo2ba?img-height=640",
      "https://d16c97c2np8a2o.cloudfront.net/ipfs/bafkreih5ciumf2b2i7t4hynfqsepbtwf3qfv6sdkjw7oekyuapikveyr4i?img-height=640",
    ],
    description: `These are the core projects working closely together in the Giveth Galaxy.`,
    applicationRefs: [
      "424:0x98720dd1925d34a2453ebc1f91c9d48e7e89ec29:127",
      "424:0x98720dd1925d34a2453ebc1f91c9d48e7e89ec29:279",
      "424:0x98720dd1925d34a2453ebc1f91c9d48e7e89ec29:166",
      "424:0x98720dd1925d34a2453ebc1f91c9d48e7e89ec29:265",
      "424:0x98720dd1925d34a2453ebc1f91c9d48e7e89ec29:280",
      "424:0xd4cc0dd193c7dc1d665ae244ce12d7fab337a008:11",
      "424:0xd4cc0dd193c7dc1d665ae244ce12d7fab337a008:129",
      "424:0xd4cc0dd193c7dc1d665ae244ce12d7fab337a008:30",
      "424:0xd4cc0dd193c7dc1d665ae244ce12d7fab337a008:83",
      "424:0xd4cc0dd193c7dc1d665ae244ce12d7fab337a008:128",
    ],
  },
  {
    id: "griffs-oss",
    author: "Griff",
    name: "Griff's Picks for OSS & Community",
    images: [
      "https://d16c97c2np8a2o.cloudfront.net/ipfs/bafkreid7724u4k4llclfw467j7hlpt6rt4pfpizjm4eldz4lnkclivcd3q?img-height=640",
      "https://d16c97c2np8a2o.cloudfront.net/ipfs/bafybeicqhsq7z3qobkfispnozzqiyu4gqa2azpy6gjbyawlarv4fhl6ufe?img-height=640",
      "https://d16c97c2np8a2o.cloudfront.net/ipfs/bafkreiggaqod3axjmtujagb4tcbxojb2gxtqsdfrcdeockxr7fn6j7b7ua?img-height=640",
      "https://d16c97c2np8a2o.cloudfront.net/ipfs/bafybeihiwolir4unpxcezqd27oyl3jar2inincc7kzu7fmvvwjhd575gce?img-height=640",
    ],
    description: `These are the projects that I know and love the work they do in the Web3 OSS and Web3 Community & Education rounds.`,
    applicationRefs: [
      "424:0x98720dd1925d34a2453ebc1f91c9d48e7e89ec29:11",
      "424:0x98720dd1925d34a2453ebc1f91c9d48e7e89ec29:127",
      "424:0x98720dd1925d34a2453ebc1f91c9d48e7e89ec29:128",
      "424:0x98720dd1925d34a2453ebc1f91c9d48e7e89ec29:145",
      "424:0x98720dd1925d34a2453ebc1f91c9d48e7e89ec29:148",
      "424:0x98720dd1925d34a2453ebc1f91c9d48e7e89ec29:15",
      "424:0x98720dd1925d34a2453ebc1f91c9d48e7e89ec29:150",
      "424:0x98720dd1925d34a2453ebc1f91c9d48e7e89ec29:152",
      "424:0x98720dd1925d34a2453ebc1f91c9d48e7e89ec29:157",
      "424:0x98720dd1925d34a2453ebc1f91c9d48e7e89ec29:166",
      "424:0x98720dd1925d34a2453ebc1f91c9d48e7e89ec29:171",
      "424:0x98720dd1925d34a2453ebc1f91c9d48e7e89ec29:172",
      "424:0x98720dd1925d34a2453ebc1f91c9d48e7e89ec29:177",
      "424:0x98720dd1925d34a2453ebc1f91c9d48e7e89ec29:178",
      "424:0x98720dd1925d34a2453ebc1f91c9d48e7e89ec29:183",
      "424:0x98720dd1925d34a2453ebc1f91c9d48e7e89ec29:20",
      "424:0x98720dd1925d34a2453ebc1f91c9d48e7e89ec29:205",
      "424:0x98720dd1925d34a2453ebc1f91c9d48e7e89ec29:223",
      "424:0x98720dd1925d34a2453ebc1f91c9d48e7e89ec29:229",
      "424:0x98720dd1925d34a2453ebc1f91c9d48e7e89ec29:231",
      "424:0x98720dd1925d34a2453ebc1f91c9d48e7e89ec29:233",
      "424:0x98720dd1925d34a2453ebc1f91c9d48e7e89ec29:243",
      "424:0x98720dd1925d34a2453ebc1f91c9d48e7e89ec29:246",
      "424:0x98720dd1925d34a2453ebc1f91c9d48e7e89ec29:253",
      "424:0x98720dd1925d34a2453ebc1f91c9d48e7e89ec29:254",
      "424:0x98720dd1925d34a2453ebc1f91c9d48e7e89ec29:265",
      "424:0x98720dd1925d34a2453ebc1f91c9d48e7e89ec29:270",
      "424:0x98720dd1925d34a2453ebc1f91c9d48e7e89ec29:277",
      "424:0x98720dd1925d34a2453ebc1f91c9d48e7e89ec29:279",
      "424:0x98720dd1925d34a2453ebc1f91c9d48e7e89ec29:280",
      "424:0x98720dd1925d34a2453ebc1f91c9d48e7e89ec29:299",
      "424:0x98720dd1925d34a2453ebc1f91c9d48e7e89ec29:304",
      "424:0x98720dd1925d34a2453ebc1f91c9d48e7e89ec29:306",
      "424:0x98720dd1925d34a2453ebc1f91c9d48e7e89ec29:32",
      "424:0x98720dd1925d34a2453ebc1f91c9d48e7e89ec29:37",
      "424:0x98720dd1925d34a2453ebc1f91c9d48e7e89ec29:42",
      "424:0x98720dd1925d34a2453ebc1f91c9d48e7e89ec29:80",
      "424:0x98720dd1925d34a2453ebc1f91c9d48e7e89ec29:97",
      "424:0xd4cc0dd193c7dc1d665ae244ce12d7fab337a008:103",
      "424:0xd4cc0dd193c7dc1d665ae244ce12d7fab337a008:11",
      "424:0xd4cc0dd193c7dc1d665ae244ce12d7fab337a008:120",
      "424:0xd4cc0dd193c7dc1d665ae244ce12d7fab337a008:122",
      "424:0xd4cc0dd193c7dc1d665ae244ce12d7fab337a008:128",
      "424:0xd4cc0dd193c7dc1d665ae244ce12d7fab337a008:129",
      "424:0xd4cc0dd193c7dc1d665ae244ce12d7fab337a008:130",
      "424:0xd4cc0dd193c7dc1d665ae244ce12d7fab337a008:136",
      "424:0xd4cc0dd193c7dc1d665ae244ce12d7fab337a008:139",
      "424:0xd4cc0dd193c7dc1d665ae244ce12d7fab337a008:157",
      "424:0xd4cc0dd193c7dc1d665ae244ce12d7fab337a008:167",
      "424:0xd4cc0dd193c7dc1d665ae244ce12d7fab337a008:169",
      "424:0xd4cc0dd193c7dc1d665ae244ce12d7fab337a008:176",
      "424:0xd4cc0dd193c7dc1d665ae244ce12d7fab337a008:18",
      "424:0xd4cc0dd193c7dc1d665ae244ce12d7fab337a008:22",
      "424:0xd4cc0dd193c7dc1d665ae244ce12d7fab337a008:25",
      "424:0xd4cc0dd193c7dc1d665ae244ce12d7fab337a008:28",
      "424:0xd4cc0dd193c7dc1d665ae244ce12d7fab337a008:30",
      "424:0xd4cc0dd193c7dc1d665ae244ce12d7fab337a008:42",
      "424:0xd4cc0dd193c7dc1d665ae244ce12d7fab337a008:62",
      "424:0xd4cc0dd193c7dc1d665ae244ce12d7fab337a008:64",
      "424:0xd4cc0dd193c7dc1d665ae244ce12d7fab337a008:68",
      "424:0xd4cc0dd193c7dc1d665ae244ce12d7fab337a008:70",
      "424:0xd4cc0dd193c7dc1d665ae244ce12d7fab337a008:78",
      "424:0xd4cc0dd193c7dc1d665ae244ce12d7fab337a008:79",
      "424:0xd4cc0dd193c7dc1d665ae244ce12d7fab337a008:81",
      "424:0xd4cc0dd193c7dc1d665ae244ce12d7fab337a008:83",
    ],
  },
];
